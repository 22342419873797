import React from "react";
import { SortableElement } from "react-sortable-hoc";
import { Tile } from "./tiles";

export const SortableTile = SortableElement(
  ({
    index,
    name,
    className,
  }: {
    index: number;
    name: string;
    className?: string;
  }) => {
    return (
        <Tile className={className} name={name} />
    );
  }
);
