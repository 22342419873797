import { Reducer, AnyAction } from "redux";
import { PlayerStateMap } from "../mahjong/reducers";

export interface TendaysState {
  deck: string[];
  top: number;
  playerState: PlayerStateMap;
  discardA: number[];
  discardB: number[];
  discardC: number[];
  code?: string;
}

export const tendays: Reducer<TendaysState> = (
  state = {
    top: 0,
    deck: [],
    playerState: {},
    discardA: [],
    discardB: [],
    discardC: [],
  },
  action: AnyAction
) => {
  switch (action.type) {
    case "TENDAYS_SETUP":
    case "SET_TENDAYS":
      const fields = { ...action };
      delete fields.type;
      return { ...state, ...fields };
    case "JOIN_PLAYER": {
      if (action.game !== "tendays" || action.code !== state.code) {
        return state;
      }
      let playerState: PlayerStateMap = { ...state.playerState };
      const me = action.email;
      let blank = -10;
      if (!playerState[me]) {
        const rack: number[] = [];
        for (let i = 0; i < 10; ++i) {
          rack.push(blank);
          blank -= 1;
        }
        playerState[me] = {
          rack: rack,
          melds: [],
        };
      }
      return {...state, playerState };
    }
    case "UPDATE_TENDAYS_RACK": {
      const ret = {...state};
      ret.playerState = {...state.playerState};
      ret.playerState[action.email] = action.rack;
      return ret;
    }

    case "DRAW_TENDAYS_TILE": {
      const ret = {...state};
      ret.playerState = {...state.playerState};
      const melds = [...ret.playerState[action.email].melds];
      ret.playerState[action.email] = {...ret.playerState[action.email]};
      melds.push(ret.top);
      ret.playerState[action.email].melds = melds;
      ret.top++;
      return ret;
    }
    case "DISCARD_TENDAYS_TILE": {
      const ret: any = {...state};
      ret.playerState = {...state.playerState};
      ret.playerState[action.email] = action.rack;
      ret[action.pile] = action.discard;
      return ret;
    }
    default:
      return state;
  }
};
