import { Reducer, AnyAction } from "redux";
import { tilesets } from "./index";

export interface CardsState {
  deck: string[];
  piles: { [pileName: string]: number[] };
  // Identifies the source of each tile in a pile.
  sources: { [pileName: string]: string[] };
  gameType: string;
  orderedPlayers: string[];
  currentPlayer: number;
  cardsPerHand: number;
  code?: string;
  showTopCard: boolean;
  tileset: string;
  isDrawerOpen: boolean;
}

export const cards: Reducer<CardsState> = (
  state = {
    deck: [],
    piles: {},
    sources: {},
    gameType: "discard",
    orderedPlayers: [],
    currentPlayer: 0,
    cardsPerHand: 5,
    showTopCard: false,
    tileset: "standard",
    isDrawerOpen: false,
  },
  action: AnyAction
) => {
  let pending = true;
  if (action.timestamp && action.timestamp !== null) {
    pending = false;
  }
  switch (action.type) {
    case "SET_INITIAL_STATE": {
      let piles: { [pileName: string]: number[] } = {};
      let sources: { [pileName: string]: string[] } = {};
      state.orderedPlayers.forEach((player) => {
        if (!piles[player + "_rack"]) {
          piles[player + "_rack"] = [];
          sources[player + "_rack"] = [];
        }
      });

      // Neat, eh?
      piles["drawPile"] = [...action.deck.keys()];
      sources["drawPile"] = [...action.deck.keys()].map((x: number) => {
        return "initial shuffle #" + x;
      });
      return {
        ...state,
        piles,
        sources,
        deck: action.deck,
        cardsPerHand: action.cardsPerHand,
        gameType: action.gameType,
        tileset: action.tileset,
        showTopCard: false,
      };
    }

    case "SHOW_TOP_CARD": {
      return { ...state, showTopCard: action.show };
    }

    case "SET_PLAYER_ORDER": {
      return {
        ...state,
        orderedPlayers: action.playerOrder,
      };
    }

    case "TAKE_CURRENT_PLAYER_TOKEN": {
      return { ...state, currentPlayer: action.currentPlayer };
    }

    case "JOIN_PLAYER": {
      if (pending || action.game !== "cards" || action.code !== state.code) {
        return state;
      }
      let piles = { ...state.piles };
      let sources = { ...state.sources };

      const me = action.email;
      if (!piles[me + "_rack"]) {
        piles[me + "_rack"] = [];
        sources[me + "_rack"] = [];
      }
      return { ...state, piles, sources };
    }

    case "MOVE_CARD": {
      let piles = { ...state.piles };
      let sources = { ...state.sources };
      if (action.sourceIndex >= piles[action.sourcePile].length) {
        console.error("Invalid index into source pile");
        return state;
      }
      piles[action.sourcePile] = [...piles[action.sourcePile]];
      sources[action.sourcePile] = [...sources[action.sourcePile]];
      let tile = piles[action.sourcePile].splice(action.sourceIndex, 1)[0];
      sources[action.sourcePile].splice(action.sourceIndex, 1);
      if (piles[action.destPile]) {
        piles[action.destPile] = [...piles[action.destPile]];
        sources[action.destPile] = [...sources[action.destPile]];
      } else {
        piles[action.destPile] = [];
        sources[action.destPile] = [];
      }
      let destIndex = action.destIndex;
      if (destIndex < 0) {
        destIndex = piles[action.destPile].length + 1 + destIndex;
      }
      piles[action.destPile].splice(destIndex, 0, tile);
      sources[action.destPile].splice(destIndex, 0, action.sourcePile);
      return { ...state, piles, sources };
    }

    case "UPDATE_PILE": {
      let piles = { ...state.piles };
      let sources = { ...state.sources };
      piles[action.pileName] = action.pile;
      sources[action.pileName] = action.pile.map(
        (x: number) => action.pileName
      );
      return { ...state, piles, sources };
    }

    case "SET_NUMBER_OF_CARDS_PER_HAND": {
      return { ...state, cardsPerHand: action.n };
    }

    case "SET_GAME_TYPE": {
      return { ...state, gameType: action.gameType };
    }

    case "SET_TILESET": {
      const t = tilesets[action.tileset];
      if (!t) return state;
      return {
        ...state,
        tileset: action.tileset,
        gameType: t.gameType,
        cardsPerHand: t.cardsPerHand,
      };
    }

    case "META": {
      let s = state;
      action.actions.foreach((x: AnyAction) => {
        s = cards(state, x);
      });
      return s;
    }

    case "OPEN_CARDS_DRAWER": {
      return {...state, isDrawerOpen: action.isOpen};
    }

    default:
      return state;
  }
};
