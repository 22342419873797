import { AnyAction } from "redux";
import { State } from "../store";

export function gamereducer(state: State, action: AnyAction): State {
  switch (action.type) {
    case "NAVIGATE_TO_GAME": {
      if (window.location.href.indexOf(action.code) === -1) {
        window.location.href = action.code;
      }
      return state;
    }
    case "ENTER_ELEVATOR": {
      const s: any = state;
      let gamestate = s[action.game];

      const players: string[] = [];
      gamestate = {
        ...gamestate,
        players,
        code: action.code,
        auth: state.auth,
        creator: action.email,
        loaded: true,
      };

      const ret: any = { ...state };
      ret[action.game] = gamestate;
      return ret;
    }
    case "JOIN_PLAYER": {
      const s: any = state;
      let gamestate = s[action.game];

      const players = [...gamestate.players];
      if (!players.includes(action.email)) {
        players.push(action.email);
      }
      gamestate = { ...gamestate, players: players };

      const ret: any = { ...state };
      ret[action.game] = gamestate;
      return ret;
    }
    case "START_GAME": {
      const s: any = state;
      let gamestate = s[action.game];

      gamestate = { ...gamestate, started: true };

      const ret: any = { ...state };
      ret[action.game] = gamestate;
      return ret;
    }
    default:
      return state;
  }
}
