import { Reducer, AnyAction } from "redux";
import { AuthState } from "../login/reducers";

export interface Scramble {
  letters: string;
  stride: number;
  width: number;
  height: number;
  auth: AuthState;
}

// We currently do not have any actions for scramble. Just return state for now.
export const scramble: Reducer<Scramble> = (
  state = {
    letters: "",
    stride: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    auth: {}
  },
  action: AnyAction
) => {
  const ret = { ...state };
  switch (action.type) {
    case "SETUP_GAME":
      ret.letters = action.letters;
      ret.stride = action.stride;
      return ret;
    case "RESIZE_BOARD":
      ret.width = action.width;
      ret.height = action.height;
      return ret;
    default:
      return state;
  }
};
