import { Reducer, AnyAction } from "redux";
export interface AuthState {
  name?: string;
  email?: string;
  photo?: string;
  signedIn?: boolean;
  authMessage?: string;
}

// auth is expecting one of these things:
//   no parameters: return initial state
//   action = { type: "SIGN_OUT" }: sign the user out
//   action = { type: "SIGN_IN", email: <addr> }: sign the user in
//   action = { type: "AUTH_UNKNOWN" }: haven't seen an auth callback yet
//   action = { type: "AUTH_WAITING", email: <addr> }: sign the user in
//   action = { type: "AUTH_ERROR", message: <message> }: display an error message
export const auth: Reducer<AuthState> = (
  state = {
    name: undefined,
    email: undefined,
    photo: undefined,
    signedIn: false,
    authMessage: undefined
  },
  action: AnyAction
) => {
  switch (action.type) {
    case "AUTH_WAITING":
      return {
        ...state,
        name: undefined,
        email: undefined,
        photo: undefined,
        signedIn: undefined,
        authMessage: undefined
      };
    case "AUTH_UNKNOWN":
      return {
        ...state,
        name: undefined,
        email: undefined,
        photo: undefined,
        signedIn: undefined,
        authMessage: "Checking if you're signed in..."
      };
    case "AUTH_ERROR":
      return {
        ...state,
        signedIn: false,
        authMessage: action.message
      };
    case "SIGN_IN":
      return {
        ...state,
        name: action.name,
        email: action.email,
        photo: action.photo,
        signedIn: true,
        authMessage: undefined
      };
    case "SIGN_OUT":
      return {
        ...state,
        signedIn: false,
        authMessage: undefined
      };
    default:
      return state;
  }
};
