import { Reducer, AnyAction } from "redux";

// Chat room will contain the following
// u1: lorem ipsum dolor amet (time)
// u2: some other text..  (time)
// _me: some pending text. [sending..]_
// _me: some other pending text. [sending..]_
// [    text field      ] [ send button ]

export interface ChatMessage {
  email: string;
  text: string;
  timestamp: number;
  pending: boolean;
}

export interface ChatRoom {
  roomMap: {
    [roomKey: string]: {
      message_ids: string[];
      idToMessageMap: { [id: string]: ChatMessage };
    };
  };
}

// Chat operates by creating pending messages (for outgoing text),
// and incorporating messages received from the network (truly created
// messages). When receiving messages, we check to see if the incoming
// message is pending and we update the pending state accordingly.
//   no parameters: return initial state
//   action = { type: "CREATE_MESSAGE_PENDING" }: add a pending message.
//   action = { type: "CREATE_MESSAGE" }: add a message.
export const chat: Reducer<ChatRoom> = (
  state = {
    roomMap: {},
  },
  action: AnyAction
) => {
  let pending = true;
  switch (action.type) {
    case "CREATE_MESSAGE":
      pending = false;
      if (state.roomMap[action.room]?.idToMessageMap[action.message_id]) {
        const new_state = { ...state };
        new_state.roomMap = { ...state.roomMap };
        new_state.roomMap[action.room] = { ...state.roomMap[action.room] };
        new_state.roomMap[action.room].idToMessageMap = {
          ...new_state.roomMap[action.room].idToMessageMap,
        };
        new_state.roomMap[action.room].idToMessageMap[action.message_id] = {
          ...new_state.roomMap[action.room].idToMessageMap[action.message_id],
          pending,
        };
        return new_state;
      }
    // eslint-disable-next-line no-fallthrough
    case "CREATE_MESSAGE_PENDING":
      const new_state = { ...state };
      new_state.roomMap = { ...state.roomMap };
      if (new_state.roomMap[action.room]) {
        new_state.roomMap[action.room] = { ...state.roomMap[action.room] };
      } else {
        new_state.roomMap[action.room] = {
          message_ids: [],
          idToMessageMap: {},
        };
      }
      new_state.roomMap[action.room].idToMessageMap = {
        ...new_state.roomMap[action.room].idToMessageMap,
      };
      new_state.roomMap[action.room].idToMessageMap[action.message_id] = {
        email: action.email,
        text: action.text,
        timestamp: action.timestamp,
        pending,
      };
      new_state.roomMap[action.room].message_ids = [
        ...new_state.roomMap[action.room].message_ids,
        action.message_id,
      ];
      let len = new_state.roomMap[action.room].message_ids.length;
      const ROOM_LIMIT = 200;
      if (len > ROOM_LIMIT) {
        new_state.roomMap[action.room].message_ids.splice(0, len - ROOM_LIMIT);
      }
      return new_state;
    default:
      return state;
  }
};
