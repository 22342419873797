import React, { Fragment, RefObject, Component, ChangeEvent } from "react";
import { ReactNode } from "react";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { State } from "../../store";
import { Game, GameState } from "../../components/game";
import * as actions from "./actions";

import classNames from "classnames";
import tabletops from "../../components/tabletops.module.css";
import styles from "./style.module.css";
import cards from "./cards.module.css";
import euchre from "./euchre.module.css";
import hearts from "./hearts.module.css";
import { CardsState } from "./reducers";
import { AuthState } from "../login/reducers";
import { Rack, Group } from "../../components/rack";
import {
  Avatar,
  FormControl,
  NativeSelect,
  FormHelperText,
  Toolbar,
  AppBar,
  IconButton,
  Typography,
  Drawer,
  ListItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import UndoIcon from "@material-ui/icons/Undo";
import SortIcon from "@material-ui/icons/Sort";
import ReplayIcon from "@material-ui/icons/Replay";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import GetAppIcon from "@material-ui/icons/GetApp";
import { shuffle } from "../../components/shuffle";
import {
  SetGameTypeAction,
  SetPlayerOrderAction,
  MoveCardAction,
  UpdatePileAction,
} from "./actions";
import { Users } from "../users/reducers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const mapStateToProps = (
  state: State
): CardsState & { auth: AuthState } & { users: Users } => ({
  ...state.cards,
  auth: state.auth,
  users: state.users,
});
const mapPropsToDispatch = actions;

const connector = connect(mapStateToProps, mapPropsToDispatch);
type Props = ConnectedProps<typeof connector> & GameState;

const gameTypes: { [key: string]: any } = {
  discard: { name: "Discarding Game" },
  tricks: { name: "Trick Taking Game" },
};

const theme = createMuiTheme({
  palette: {
    primary: {
      // The main colour is meant to match the felt,
      // and should look nicely a bit darker as it
      // has no texture.
      main: "#004e00",
      light: "#3a7b2e",
      dark: "#002600",
    },
    secondary: {
      main: "#424242",
      light: "#6d6d6d",
      dark: "#1b1b1b",
    },
  },
});

export const tilesets: {
  [key: string]: {
    tiles: any;
    name: string;
    gameType: string;
    cardsPerHand: number;
  };
} = {
  standard: {
    tiles: cards,
    name: "Standard Deck",
    gameType: "discard",
    cardsPerHand: 8,
  },
  euchre: {
    tiles: euchre,
    name: "Euchre Deck",
    gameType: "tricks",
    cardsPerHand: 5,
  },
  hearts: {
    tiles: hearts,
    name: "3-handed Hearts Deck",
    gameType: "tricks",
    cardsPerHand: 17,
  },
};

type HandProps = {
  deck: string[];
  cards: number[];
  place: (position: number) => void;
  tileset: string;
};

class Hand extends Component<HandProps> {
  render(): ReactNode {
    const props = this.props;
    const tiles = tilesets[this.props.tileset].tiles;
    return (
      <Fragment>
        {tiles && (
          <Rack rack={props.cards} melds={[]} deck={props.deck}>
            <Group
              className={styles.rack}
              tileClassName={[
                styles.cardstyle,
                styles.overlapping,
                styles.short,
              ].join(" ")}
              backClassName={styles.BLUE_BACK}
              deck={props.deck}
              tiles={tiles}
              indices={props.cards}
              place={props.place}
            />
          </Rack>
        )}
      </Fragment>
    );
  }
}

function backs(xs: number[]) {
  return xs.map((x) => -x - 1);
}

type PlayerUIProps = {
  rack: string;
  deck: string[];
  currentPlayer: string;
  piles: { [pileName: string]: number[] };
  users: Users;
  takeCurrentPlayerToken: () => void;
  tileset: string;
};

class PlayerUI extends Component<PlayerUIProps> {
  noop = () => {};

  render(): ReactNode {
    return (
      <div>
        <div className={styles.row}>
          <Avatar
            classes={{ root: styles.paddedDisc }}
            key="avatar"
            alt={this.props.rack}
            src={this.props.users.users[this.props.rack].photo}
          />
          <Hand
            cards={backs(this.props.piles[this.props.rack + "_rack"])}
            deck={this.props.deck}
            place={this.noop}
            tileset={this.props.tileset}
          />
          {this.props.currentPlayer === this.props.rack && (
            <div className={styles.column}>
              <div
                className={classNames(
                  styles.currentPlayerToken,
                  styles.paddedDisc
                )}
                onClick={this.props.takeCurrentPlayerToken}
              ></div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

class Cards extends Game<Props> {
  static defaultProps: GameState = {
    game: "cards",
    title: "Standard Playing Cards",
    description: "Use these cards to play any card game!",
    started: false,
    creator: "",
    loaded: false,
    players: [],
    auth: {},
    pendingActions: false,
  };

  createSetupAction(code: string): AnyAction {
    const deck: string[] = [];
    Object.keys(tilesets[this.props.tileset].tiles).forEach((cardname) =>
      deck.push(cardname)
    );

    return new actions.SetInitialStateAction(
      shuffle(deck),
      this.props.cardsPerHand,
      this.props.gameType,
      this.props.tileset
    );
  }

  gameWillStart() {
    let players = shuffle(this.props.players);
    this.commitAction(new SetPlayerOrderAction(players));
  }

  setNumberOfCardsPerHand = (e: ChangeEvent<HTMLSelectElement>) => {
    let text = e.target.value || "5";
    let n = parseInt(text);
    if (!n) return;
    this.dispatch(new actions.SetNumberOfCardsPerHandAction(n));
  };

  setGameType = (e: ChangeEvent<HTMLSelectElement>) => {
    this.dispatch(new SetGameTypeAction(e.target.value));
  };

  setTileset = (e: ChangeEvent<HTMLSelectElement>) => {
    this.dispatch(new actions.SetTilesetAction(e.target.value));
  };

  range = (low: number, high: number): number[] => {
    let ret = [];
    for (var i = low; i < high; ++i) {
      ret.push(i);
    }
    return ret;
  };

  input: RefObject<HTMLInputElement> = React.createRef();
  renderGameSettings(): ReactNode {
    return (
      <div>
        <h1>Settings</h1>
        <div>
          <form
            onSubmit={(event: any) => {
              event.preventDefault();
              this.input?.current?.blur();
            }}
          >
            <FormControl>
              <NativeSelect
                value={this.props.tileset}
                onChange={this.setTileset}
                inputProps={{
                  name: "variation",
                  id: "variation-native-helper",
                }}
              >
                <option aria-label="None" value="" />
                {Object.keys(tilesets).map((key: string) => (
                  <option key={key} value={key}>
                    {tilesets[key].name}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText>Choose your variation (cards)</FormHelperText>
            </FormControl>
            <FormControl>
              <NativeSelect
                value={this.props.gameType}
                onChange={this.setGameType}
                inputProps={{
                  name: "variation",
                  id: "variation-native-helper",
                }}
              >
                <option aria-label="None" value="" />
                {Object.keys(gameTypes).map((key: string) => (
                  <option key={key} value={key}>
                    {gameTypes[key].name}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText>Choose your game type.</FormHelperText>
            </FormControl>
            <FormControl>
              <NativeSelect
                value={this.props.cardsPerHand}
                onChange={this.setNumberOfCardsPerHand}
                inputProps={{
                  name: "variation",
                  id: "variation-native-helper",
                }}
              >
                <option aria-label="None" value="" />
                {this.range(
                  1,
                  Object.keys(tilesets[this.props.tileset].tiles).length
                ).map((key: number) => (
                  <option key={key} value={key}>
                    {key + " cards"}
                  </option>
                ))}
              </NativeSelect>
              <FormHelperText>Number of cards/hand</FormHelperText>
            </FormControl>
          </form>
        </div>
      </div>
    );
  }

  onUndo = async (): Promise<void> => {
    this.undoLastAction();
  };

  onSort = async (): Promise<void> => {
    const me = this.props.auth.email || "";
    const pileName = me + "_rack";
    const pile = [...this.props.piles[pileName]];
    const deck = this.props.deck || [];
    pile.sort(function (a, b) {
      const sa = deck[a];
      const sb = deck[b];
      if (sa < sb) {
        return -1;
      }
      if (sa > sb) {
        return 1;
      }
      return 0;
    });
    this.commitAction(new UpdatePileAction(pileName, pile));
  };

  onNewRound = () => {
    if (this.props.code) {
      this.commitAction(this.createSetupAction(this.props.code));
    }
  };

  onReshuffle = () => {
    let discards = [...this.props.piles["trick"]];
    let moves: AnyAction[] = [];
    while (discards.length > 1) {
      const j = Math.floor(Math.random() * (discards.length - 1));
      discards.splice(j, 1);
      moves.push(new MoveCardAction("trick", j, "drawPile", 0));
    }
    moves.forEach((x) => this.commitAction(x));
  };

  onDeal = () => {
    for (let i = 0; i < this.props.cardsPerHand; ++i) {
      for (let p = 0; p < this.props.orderedPlayers.length; ++p) {
        let deck = "drawPile";
        let rack = this.props.orderedPlayers[p] + "_rack";
        this.commitAction(new MoveCardAction(deck, 0, rack, 0));
      }
    }
  };

  onTakeTrick = () => {
    const me = this.props.auth.email || "";
    const tricks = me + "_tricks"; // TODO: encapsulate this naming
    for (let i = 0; i < this.props.piles["trick"]?.length; ++i) {
      this.commitAction(new actions.MoveCardAction("trick", 0, tricks, -1));
    }
  };

  unplace(pile: string) {
    return (position: number): void => {
      const me = this.props.auth.email || "";
      const rack = me + "_rack"; // TODO: encapsulate this naming
      this.commitAction(new actions.MoveCardAction(pile, position, rack, -1));
    };
  }

  place = (position: number): void => {
    const me = this.props.auth.email || "";
    const rack = me + "_rack"; // TODO: encapsulate this naming
    this.commitAction(new actions.MoveCardAction(rack, position, "trick", -1));
  };

  showTopCard = (show: boolean): void => {
    this.commitAction(new actions.ShowTopCardAction(show));
  };

  onClickedDrawPile = (): void => {
    const topCardIsUp = this.props.showTopCard;
    if (this.props.gameType === "tricks") {
      this.showTopCard(!topCardIsUp);
    }
    if (this.props.gameType === "discard" || topCardIsUp) {
      this.unplace("drawPile")(-1);
    }
  };

  toggleDrawerOpen = (): void => {
    this.dispatch(
      new actions.OpenCardsDrawerAction(!this.props.isDrawerOpen)
    );
  };

  getRackNames(players: string[], me: string) {
    let allRackNames: string[] = [];
    let myIndex = players.indexOf(me);
    if (myIndex === -1) myIndex = 0; // allow an observer to see it from player 0's PoV
    allRackNames = [
      ...players.slice(myIndex + 1, players.length),
      ...players.slice(0, myIndex),
    ];
    //allRackNames = allRackNames.map((x) => x + "_rack");

    if (allRackNames.length % 3) {
      allRackNames.push("");
    }
    if (allRackNames.length % 3) {
      // find us. add something before us.
      allRackNames.splice(0, 0, "");
    }

    const size = allRackNames.length / 3;
    return {
      leftRackNames: allRackNames.slice(0, size),
      topRackNames: allRackNames.slice(size, 2 * size),
      rightRackNames: allRackNames.slice(2 * size, 3 * size),
    };
  }

  takeCurrentPlayerToken = () => {
    const me = this.props.auth.email || "";
    const player = this.props.orderedPlayers.indexOf(me);
    console.log("Take Token for #" + player);
    this.commitAction(new actions.TakeCurrentPlayerTokenAction(player));
  };

  renderGame(): ReactNode {
    const props = this.props;
    const deck = props.deck;
    const me = props.auth.email || "";
    const myRack = me + "_rack"; // TODO: encapsulate this naming
    const myTricks = me + "_tricks"; // TODO: encapsulate this naming
    let numTricks = props.piles[myTricks]?.length;
    numTricks = numTricks ? numTricks / props.orderedPlayers.length : 0;
    let users = props.users.users;
    const tiles = tilesets[this.props.tileset].tiles;
    let avatarsToShow =
      props.gameType === "discard"
        ? props.sources["trick"]?.slice(-1)
        : props.sources["trick"];

    const { leftRackNames, topRackNames, rightRackNames } = this.getRackNames(
      props.orderedPlayers,
      me
    );

    return (
      <ThemeProvider theme={theme}>
        <div className={styles.column}>
          <AppBar position="sticky">
            <Toolbar className={styles.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Show"
                onClick={this.toggleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit">
                <span className={styles.header}>Cards</span>
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            open={this.props.isDrawerOpen}
            onClose={this.toggleDrawerOpen}
          >
            <ListItem
              button
              onClick={this.onUndo}
              classes={{ root: styles.button }}
            >
              <div className={styles.drawerIcon}>
                <UndoIcon />
              </div>
              Undo
            </ListItem>
            <ListItem
              button
              onClick={this.onSort}
              classes={{ root: styles.button }}
            >
              <div className={styles.drawerIcon}>
                <SortIcon />
              </div>
              Sort
            </ListItem>
            <ListItem
              button
              onClick={this.onNewRound}
              classes={{ root: styles.button }}
            >
              <div className={styles.drawerIcon}>
                <ReplayIcon />
              </div>
              New Round
            </ListItem>
            <ListItem
              button
              onClick={this.onReshuffle}
              classes={{ root: styles.button }}
            >
              <div className={styles.drawerIcon}>
                <ShuffleIcon />
              </div>
              Reshuffle Discard
            </ListItem>
            <ListItem
              button
              onClick={this.onDeal}
              classes={{ root: styles.button }}
            >
              <div className={styles.drawerIcon}>
                <RecentActorsIcon />
              </div>
              Deal
            </ListItem>

            {this.props.gameType === "tricks" && (
              <ListItem
                button
                onClick={this.onTakeTrick}
                classes={{ root: styles.button }}
              >
                <div className={styles.drawerIcon}>
                  <GetAppIcon />
                </div>
                Take Trick
              </ListItem>
            )}
          </Drawer>
          <div
            className={classNames(
              styles.green,
              styles.tabletop,
              tabletops.felt
            )}
          >
            <div className={styles.left}>
              {leftRackNames.map((rack) => {
                return (
                  rack && (
                    <PlayerUI
                      rack={rack}
                      piles={props.piles}
                      deck={props.deck}
                      users={props.users}
                      currentPlayer={props.orderedPlayers[props.currentPlayer]}
                      takeCurrentPlayerToken={this.takeCurrentPlayerToken}
                      tileset={props.tileset}
                    />
                  )
                );
              })}
            </div>
            <div className={styles.middle}>
              <div className={styles.top}>
                {topRackNames.map((rack) => {
                  return (
                    rack && (
                      <PlayerUI
                        rack={rack}
                        piles={props.piles}
                        deck={props.deck}
                        users={props.users}
                        currentPlayer={
                          props.orderedPlayers[props.currentPlayer]
                        }
                        takeCurrentPlayerToken={this.takeCurrentPlayerToken}
                        tileset={props.tileset}
                      />
                    )
                  );
                })}
              </div>
              <div className={styles.playarea}>
                <div className={styles.row}>
                  {props.piles["trick"] && (
                    <Fragment>
                      <div className={styles.centeredColumn}>
                        {avatarsToShow.map((source: string) => {
                          let name = source.replace(/_rack$/, "");
                          return (
                            <Avatar
                              classes={{ root: styles.paddedDisc }}
                              key="avatar"
                              alt={name}
                              src={users[name].photo}
                            />
                          );
                        })}
                        {this.props.gameType === "tricks" && (
                          <ListItem
                            button
                            onClick={this.onTakeTrick}
                            classes={{ root: styles.button }}
                          >
                            <GetAppIcon />
                          </ListItem>
                        )}
                      </div>
                      <Rack
                        rack={props.piles["trick"]}
                        melds={[]}
                        deck={this.props.deck}
                      >
                        <Group
                          className={styles.rack}
                          tileClassName={classNames({
                            [styles.cardstyle]: true,
                            [styles.stack]: this.props.gameType === "discard",
                            [styles.overlapping]:
                              this.props.gameType === "tricks",
                          })}
                          deck={deck}
                          tiles={tiles}
                          place={this.unplace("trick")}
                          indices={props.piles["trick"]}
                        />
                      </Rack>
                    </Fragment>
                  )}
                  {props.piles["drawPile"] && (
                    <Rack
                      rack={props.piles["drawPile"]}
                      melds={[]}
                      deck={this.props.deck}
                    >
                      <Group
                        className={styles.rack}
                        tileClassName={[styles.cardstyle, styles.stack].join(
                          " "
                        )}
                        backClassName={styles.BLUE_BACK}
                        deck={deck}
                        tiles={tiles}
                        place={this.onClickedDrawPile}
                        indices={
                          props.showTopCard
                            ? props.piles["drawPile"]
                            : backs(props.piles["drawPile"])
                        }
                      />
                    </Rack>
                  )}
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.grow}></div>
                <Avatar
                  classes={{ root: styles.paddedDisc }}
                  key="avatar"
                  alt={me}
                  src={users[me].photo}
                />
                <Hand
                  cards={props.piles[myRack]}
                  deck={this.props.deck}
                  place={this.place}
                  tileset={this.props.tileset}
                />
                {this.props.orderedPlayers[this.props.currentPlayer] === me && (
                  <div
                    className={classNames(
                      styles.currentPlayerToken,
                      styles.paddedDisc
                    )}
                  ></div>
                )}
                <div className={styles.grow}></div>
                <div
                  className={classNames(styles.column, styles.fixedBottomRight)}
                >
                  <div>
                    {this.props.gameType === "tricks" && (<div>{Math.floor(numTricks)}</div>)}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.right}>
              {rightRackNames.map((rack) => {
                return (
                  rack && (
                    <PlayerUI
                      rack={rack}
                      piles={props.piles}
                      deck={props.deck}
                      users={props.users}
                      currentPlayer={props.orderedPlayers[props.currentPlayer]}
                      takeCurrentPlayerToken={this.takeCurrentPlayerToken}
                      tileset={props.tileset}
                    />
                  )
                );
              })}
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

export default connect(mapStateToProps, mapPropsToDispatch)(Cards);
