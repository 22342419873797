import { MahjongState, MahjongRack } from "./reducers";

export function setFieldsAction(
  fields: MahjongState
): MahjongState & { type: string } {
  return { ...fields, type: "SET_FIELDS" };
}

function typeConstantFromClass(s: string) {
  return s
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + "_" + m[1];
    })
    .toUpperCase()
    .replace(/_ACTION$/, "");
}
export class BaseAction {
  type: string;
  constructor() {
    this.type = typeConstantFromClass(this.constructor.name);
  }
}

export class UpdateRackAction extends BaseAction {
  constructor(
    readonly email: string,
    readonly rack: MahjongRack,
  ) {
    super();
    this.type = "UPDATE_RACK";
  }
}

export class DrawTileAction extends BaseAction {
  constructor(
    readonly email: string,
  ) {
    super();
    this.type = "DRAW_TILE";
  }
}

export class TakeDiscardedTileAction extends BaseAction {
  constructor(
    readonly email: string,
  ) {
    super();
    this.type = "TAKE_DISCARDED_TILE";
  }
}

export class DiscardTileAction extends BaseAction {
  constructor(readonly email: string, readonly rack: MahjongRack, readonly discarded: number) {
    super();
    this.type = "DISCARD_TILE";
  }
}

export class PlayerOrderAction extends BaseAction {
  constructor(readonly players: string[]) {
    super();
    this.type = "PLAYER_ORDER";
  }
}
