export function createMessageAction(
  message_id: string,
  room: string,
  email: string,
  text: string,
  timestamp: number
): {
  type: string;
  room: string;
  email: string;
  text: string;
  timestamp: number;
  message_id: string;
} {
  return { type: "CREATE_MESSAGE", room, email, text, timestamp, message_id };
}

export function createMessagePendingAction(
  message_id: string,
  room: string,
  email: string,
  text: string,
  timestamp: number
): {
  type: string;
  room: string;
  email: string;
  text: string;
  timestamp: number;
  message_id: string;
} {
  return { type: "CREATE_MESSAGE_PENDING", room, email, text, timestamp, message_id };
}
