import React from "react";
import { ReactNode } from "react";
import { AnyAction } from "redux";
import * as actions from "./actions";
import { connect, ConnectedProps } from "react-redux";
import { Scramble } from "./reducers";
import { State, store } from "../../store";
import styles from "./style.module.css";
import { generateScrambleGame } from "./game";
import classNames from "classnames";
import { Game, GameState } from "../../components/game";
import { AuthState } from "../login/reducers";

const mapStateToProps = (state: State): Scramble & { auth: AuthState } => ({
  ...state.scramble,
  auth: state.auth,
});
const mapPropsToDispatch = actions;

const connector = connect(mapStateToProps, mapPropsToDispatch);
type Props = ConnectedProps<typeof connector> & GameState;

class ScrambleGame extends Game<Props> {
  static defaultProps: GameState = {
    game: "scramble",
    title: "Fun with words",
    description: "This is scrambled-word game. You'll have a blast!",
    started: false,
    creator: "",
    loaded: false,
    players: [],
    auth: {},
    pendingActions: false,
  };

  handleResize() {
    store.dispatch(
      actions.resizeBoardAction(window.innerWidth, window.innerHeight)
    );
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("resize", this.handleResize);
  }

  createSetupAction(): AnyAction {  
    let game = generateScrambleGame();
    return actions.setupGameAction(game.letters, game.stride);
  };

  renderGame(): ReactNode {
    const props = this.props;
    let index = 0;
    const VERTICAL_FUDGE_PX = 0.2 * props.height;
    const HORIZONTAL_FUDGE_PX = 30;
    let cols = props.stride;
    let rows = props.letters.length / props.stride;
    let screenWidth = props.width - HORIZONTAL_FUDGE_PX;
    let screenHeight = props.height - VERTICAL_FUDGE_PX;
    let screenAspectRatio = screenWidth / screenHeight;
    let boardAspectRatio = cols / rows;
    let cellSize = 0;
    let boardWidth = 0;

    if (boardAspectRatio > screenAspectRatio) {
      // Board is "wider" than screen. Base size on width.
      cellSize = screenWidth / cols;
      boardWidth = screenWidth;
    } else {
      // Board is "thinner" than screen. Base size on height.
      cellSize = screenHeight / rows;
      boardWidth = screenHeight * boardAspectRatio;
    }

    let containerStyle = {
      width: boardWidth + "px"
    };

    let cellStyle = {
      width: cellSize + "px",
      height: cellSize + "px",
      lineHeight: Math.floor(cellSize) + "px",
      fontSize: Math.floor(cellSize * 0.5) + "px"
    };

    return (
      <div className={styles.background}>
        <h1>Scramble Game Code: {props.code}</h1>
        <div className={styles.container}>
          <div className={styles.inner} style={containerStyle}>
            {props.letters.split("").map(letter => {
              let className =
                letter === "_"
                  ? classNames(styles.cell, styles.blank)
                  : classNames(styles.cell);
              return (
                <div className={styles.cell_container}>
                  <span key={index++} className={className} style={cellStyle}>
                    {letter === "Q" ? "Qu" : letter}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default connector(ScrambleGame);
