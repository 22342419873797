import firebase from "./components/firebase";
import { createStore, compose, combineReducers, AnyAction } from "redux";
import { auth } from "./routes/login/reducers";
import { chat } from "./routes/chat/reducers";
import { users } from "./routes/users/reducers";
import { scramble } from "./routes/scramble/reducers";
import { sketch } from "./routes/sketch/reducers";
import { gamereducer } from "./components/gamereducer";
import { cards } from "./routes/cards/reducers";
import { mahjong } from "./routes/mahjong/reducers";
import { tendays } from "./routes/tendays/reducers";
import * as userActions from "./routes/users/actions";
import * as loginActions from "./routes/login/actions";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const combined = combineReducers({
  auth,
  chat,
  users,
  scramble,
  sketch,
  cards,
  mahjong,
  tendays,
});
export type State = ReturnType<typeof combined>;
function allReducers(state: State | undefined, action: AnyAction): State {
  const before = JSON.stringify(state);
  let nextState = combined(state, action);
  // give gamereducer the global state so that it can muck with any
  // game.
  nextState = gamereducer(nextState, action);
  const after = JSON.stringify(state);
  if (before !== after) {
    console.error("error: mutated state", action);
  }
  return nextState;
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(allReducers, composeEnhancers());

export function dispatchLocalAction(action: AnyAction): void {
  // This should rarely be used. It applies an action and does not send it to
  // firestore.
  store.dispatch(JSON.parse(JSON.stringify(action)));
};

const firebaseAuth = firebase.auth();

function updateAuthState(user: firebase.User | null): void {
  if (user && user.email) {
    store.dispatch(
      loginActions.signInAction(user.displayName, user.email, user.photoURL)
    );
    firebase
      .firestore()
      .collection("users")
      .doc(user.email)
      .set({
        name: user.displayName,
        email: user.email,
        photo: user.photoURL,
        activity_timestamp: new Date().getTime(),
      })
      .catch((error: Error) => {
        // TODO: Surface this error state in the UI.
        console.error(error);
      });
  } else if (user && !user.email) {
    store.dispatch(
      loginActions.authErrorAction("User set but user.email == null?")
    );
  } else {
    store.dispatch(loginActions.signOutAction());
  }
}

store.dispatch(loginActions.authUnknown());
firebaseAuth.onAuthStateChanged(updateAuthState);

function observeUserList(): void {
  const query = firebase.firestore().collection("users");

  query.onSnapshot(function (snapshot) {
    snapshot.docChanges().forEach(function (change) {
      const user = change.doc.data();
      const action = userActions.updateUserAction(
        user.name,
        user.email,
        user.photo,
        user.activity_timestamp
      );
      store.dispatch(action);
    });
  });
}
observeUserList();
