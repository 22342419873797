import React from "react";
import { Component, ReactNode } from "react";
import { GameLobby } from "./gamelobby";
import { GameElevator } from "./gameelevator";
import { AuthState } from "../routes/login/reducers";
import firebase from "../components/firebase";
import { store } from "../store";
import { AnyAction, Reducer } from "redux";
import * as actions from "./gameactions";
import { navigateToGameAction } from "./gameactions";

export type GameState = {
  game: string;
  title: string;
  description: string;
  creator: string;
  started: boolean;
  loaded: boolean;
  players: string[];
  code?: string;
  auth: AuthState;
  pendingActions: boolean;
};

type S = { loaded: boolean };

export abstract class Game<T extends GameState> extends Component<T, S> {
  constructor(props: T) {
    super(props);
    this.state = { loaded: false };
  }

  renderGameSettings(): ReactNode {
    return undefined;
  }
  renderGameSetupStatus(): ReactNode {
    return undefined;
  }
  abstract createSetupAction(code: string): AnyAction;
  abstract renderGame(): React.ReactNode;
  gameWillStart() {}

  setup = async (doc: firebase.firestore.DocumentReference): Promise<void> => {
    await this.writeSetup(doc, this.createSetupAction(doc.id));
  };

  async writeSetup(
    doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    action: any
  ): Promise<void> {
    if (!this.props.auth) {
      throw new Error("Unauthenticated user");
    }
    const creator = this.props.auth.email as string;
    const code = doc.id;
    await doc.set({
      id: doc.id,
      loaded: true,
      creator: this.props.auth.email,
    });
    await this.commitActionToDoc(
      doc,
      actions.enterElevatorAction(creator, this.props.game, code)
    );
    await this.commitActionToDoc(doc, { ...action, code });
    await this.commitActionToDoc(
      doc,
      navigateToGameAction(this.props.game, code)
    );
    if (!this.props.loaded) {
      window.requestAnimationFrame(() => this.loadGame(code));
    }
  }

  async commitActionToDoc(
    doc: firebase.firestore.DocumentReference,
    action: AnyAction
  ): Promise<void> {
    let pendingAction = await doc.collection("actions").doc();
    await pendingAction.set({
      ...action,
      id: pendingAction.id,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  async undoLastAction(): Promise<void> {
    // There should only be one action, but it returns a list.
    const actions = await firebase
      .firestore()
      .collection(this.props.game)
      .doc(this.props.code)
      .collection("actions")
      .orderBy("timestamp", "desc")
      .limit(1)
      .get();
    actions.forEach((doc) => doc.ref.delete());
  }

  async commitAction(action: AnyAction): Promise<void> {
    const gameDoc = firebase
      .firestore()
      .collection(this.props.game)
      .doc(this.props.code);
    await this.commitActionToDoc(gameDoc, action);
  }

  async commitPreAction(action: AnyAction): Promise<void> {
    const gameDoc = firebase
      .firestore()
      .collection(this.props.game)
      .doc("preactions");
    await this.commitActionToDoc(gameDoc, action);
  }

  realtimeActionPath(): string {
    return this.props.game + "/" + this.props.code + "/realtimeActions";
  }
  async commitRealtimeAction(action: AnyAction): Promise<void> {
    const timestampId = "TS" + new Date().getTime();
    const path = this.realtimeActionPath() + "/" + timestampId;
    await firebase.database().ref(path).set(action);
  }

  async clearRealtimeActions(): Promise<void> {
    const path = this.realtimeActionPath();
    console.log("Remove all data at? " + path);
    await firebase.database().ref(path).remove();
  }

  unsubscribe?: Function = undefined;
  unsubscribeRealtime?: Function = undefined;
  unsubscribePreactions?: Function = undefined;

  async loadGame(code: string): Promise<void> {
    const docRef = firebase.firestore().collection(this.props.game).doc(code);
    const doc = await docRef.get();
    const data = doc.data();
    if (!data) throw new Error("No game state");
    if (this.unsubscribe) {
      console.error("Loaded a game with a game already loaded.");
      this.unsubscribe();
    }
    const collection = docRef.collection("actions");
    const optionalWhere = this.props.pendingActions
      ? collection
      : collection.where("timestamp", ">", new Date("2000-01-01"));
    this.unsubscribe = optionalWhere
      .orderBy("timestamp", "asc")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "removed") {
            this.unsubscribe?.();
            this.unsubscribeRealtime?.();
            this.unsubscribePreactions?.();
            this.loadGame(code);
            return;
          }
          store.dispatch(change.doc.data() as AnyAction);
        });
        this.setState({ loaded: true });
      });
    const preactions = firebase
      .firestore()
      .collection(this.props.game)
      .doc("preactions")
      .collection("actions");
    this.unsubscribePreactions = preactions.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        store.dispatch(change.doc.data() as AnyAction);
      });
    });
    let childAddedCallback = firebase
      .database()
      .ref(this.realtimeActionPath())
      .on("child_added", (action) => {
        store.dispatch(action.val() as AnyAction);
      });
    this.unsubscribeRealtime = () => {
      firebase
        .database()
        .ref(this.realtimeActionPath())
        .off("child_added", childAddedCallback);
    };
  }

  dispatch(action: AnyAction) {
    store.dispatch(JSON.parse(JSON.stringify(action)));
  }

  joinGame = async (): Promise<void> => {
    if (!this.props.auth.email) {
      throw new Error(
        "Unauthenticated user joining game " +
          this.props.game +
          "(" +
          this.props.code +
          ")"
      );
    }
    let code: string = this.props.code as string;
    this.commitAction(
      actions.joinPlayerAction(this.props.auth.email, this.props.game, code)
    );
  };

  startGame = async (): Promise<void> => {
    this.gameWillStart();
    let code: string = this.props.code as string;
    this.commitAction(actions.startGameAction(this.props.game, code));
  };

  componentWillUnmount(): void {
    this.unsubscribe?.();
    this.unsubscribeRealtime?.();
    this.unsubscribePreactions?.();
  }

  componentDidMount() {
    const props = this.props;
    // !props.gameloaded --> display loading...
    if (props.code && !props.loaded) {
      this.loadGame(props.code as string);
    }
  }

  render(): ReactNode {
    // !props.code --> display the lobby; requires game, title, description
    // may need to show settings.
    const props = this.props;
    const code = props.code;
    if (!code) {
      return (
        <GameLobby
          game={props.game}
          setup={this.setup}
          title={props.title}
          description={props.description}
        >
          {this.renderGameSettings()}
        </GameLobby>
      );
    }

    if (!this.state.loaded) {
      return <div>Loading...</div>;
    }

    // !props.started --> display waiting area with directions
    if (!props.started) {
      return (
        <GameElevator
          code={props.code as string}
          creator={props.creator as string}
          players={props.players}
          auth={props.auth}
          game={props.game}
          title={props.title}
          joinGame={this.joinGame}
          startGame={this.startGame}
        >
          {this.renderGameSetupStatus()}
        </GameElevator>
      );
    }
    // any other state means game is loaded.
    return this.renderGame();
  }
}

export type PendingState<T extends GameState> = {
  pending: T;
  confirmed: T;
  pending_actions: AnyAction[];
};

export function wrap_reducer<T extends GameState>(
  reducer: Reducer<T>
): Reducer<PendingState<T>> {
  return (
    state = {
      pending: reducer(undefined, { type: "@INIT" }),
      confirmed: reducer(undefined, { type: "@INIT" }),
      pending_actions: [],
    },
    action: AnyAction
  ) => {
    const new_state = { ...state };
    if (action.timestamp === null) {
      new_state.pending_actions = [...state.pending_actions];
      new_state.pending_actions.push(action);
      new_state.pending = reducer(new_state.pending, action);
    } else {
      new_state.confirmed = reducer(new_state.confirmed, action);
      if (
        new_state.pending_actions.length &&
        action.id === new_state.pending_actions[0].id
      ) {
        new_state.pending_actions = state.pending_actions.slice(1);
      } else {
        // if the incoming action isn't in the pending list, rewind
        // and replay pending actions ont he confirmed state in order
        // to rebase the pending state on the committed state.
        new_state.pending = new_state.confirmed;
        for (let action of new_state.pending_actions) {
          new_state.pending = reducer(new_state.pending, action);
        }
      }
    }
    return new_state;
  };
}
