import firebase from "firebase";

export const createGame = async (
  game: string
): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> => {
  function getRandomLetter(): string {
    // NB: this is not the full alphabet; we omit letters that are
    // easily confused (eg, I and O).
    const alphabet: string[] = "ABCDEFGHJKLMNPQRSTUVWXYZ".split("");
    return alphabet[Math.floor(Math.random() * alphabet.length)];
  }

  function createCode(): string {
    let code = "";
    for (let i = 0; i < 4; i++) {
      code += getRandomLetter();
    }
    return code;
  }
  let code = "";
  let doc = null;
  while (true) {
    code = createCode();
    doc = firebase
      .firestore()
      .collection(game)
      .doc(code);
    if (!(await doc.get()).exists) break;
  }

  return doc;
};
