import { Reducer, AnyAction } from "redux";

export interface User {
  name: string | undefined;
  photo: string | undefined;
  activity_timestamp: number;
}

export interface Users {
  users: {
    [email: string]: User;
  };
}

// users receives updates to the user list
// action = { type: "UPDATE_USER" }: update the user's information.
export const users: Reducer<Users> = (
  state = {
    users: {}
  },
  action: AnyAction
) => {
  switch (action.type) {
    case "UPDATE_USER":
      const new_state = { ...state };
      new_state.users = { ...new_state.users };
      new_state.users[action.email] = {
        name: action.name,
        photo: action.photo,
        activity_timestamp: action.activity_timestamp
      };
      return new_state;
    default:
      return state;
  }
};
