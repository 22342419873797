export function setupGameAction(
  letters: string,
  stride: number,
): {
  type: string;
  letters: string;
  stride: number;
} {
  return { type: "SETUP_GAME", letters, stride };
}

export function submitWordAction(
  word: string,
  email: string
): {
  type: string;
  word: string;
  email: string;
} {
  return { type: "SUBMIT_WORD", word, email };
}

export function resizeBoardAction(
  width: number,
  height: number
): {
  type: string;
  width: number;
  height: number;
} {
  return { type: "RESIZE_BOARD", width, height }
}