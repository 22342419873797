export function updateUserAction(
  name: string | null,
  email: string,
  photo: string | null,
  activity_timestamp: number
): {
  type: string;
  email: string;
  name: string | null;
  photo: string | null;
  activity_timestamp: number;
} {
  return { type: "UPDATE_USER", email, name, photo, activity_timestamp };
}
