import React from "react";
import { Component, ReactNode } from "react";
import * as actions from "./actions";
import { connect, ConnectedProps } from "react-redux";
import { Users } from "./reducers";
import { State } from "../../store";

const mapStateToProps = (state: State): Users => ({
  ...state.users
});
const mapPropsToDispatch = actions;

const connector = connect(mapStateToProps, mapPropsToDispatch);
type Props = ConnectedProps<typeof connector>;

class UserList extends Component<Users> {
  render() : ReactNode {
    const props = this.props;
    return (
      <div>
        {Object.entries(props.users).map(([email, details]) => {
          return (
            <pre key={email}>
              {email}: {JSON.stringify(details)}
            </pre>
          );
        })}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapPropsToDispatch)(UserList);
