import React, { RefObject, ChangeEvent } from "react";
import { ReactNode } from "react";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { State, dispatchLocalAction } from "../../store";
import { Game, GameState } from "../../components/game";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DeleteForever, FormatColorFill } from "@material-ui/icons";
import Chat from "../chat/index";
import * as actions from "./actions";

import styles from "./style.module.css";
import { SegmentState, SketchState, NUM_ROUNDS } from "./reducers";
import { AuthState } from "../login/reducers";
import {
  DrawPointsAction,
  PenWidthAction,
  PenColorAction,
  ChooseWordAction,
  ClearInFlightAction,
  CustomColorAction,
  SetSketchVariantAction,
  LateJoinAction,
  NextPlayerAction,
  RemovePlayerAction,
} from "./actions";
import { User, Users } from "../users/reducers";
import {
  Avatar,
  Button,
  FormControl,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import classNames from "classnames";
import { words, catWords } from "./words";
import { editDistance } from "../../components/editdistance";

const mapStateToProps = (
  state: State
): SketchState & { auth: AuthState } & { users: Users } => ({
  ...state.sketch,
  auth: state.auth,
  users: state.users,
});
const mapPropsToDispatch = actions;

const connector = connect(mapStateToProps, mapPropsToDispatch);
type Props = ConnectedProps<typeof connector> & GameState;

export const shuffle = (deck: string[]): string[] => {
  const shuffled = [...deck];
  for (let i = shuffled.length - 1; i > 0; --i) {
    const j = Math.floor(Math.random() * i);
    const t = shuffled[j];
    shuffled[j] = shuffled[i];
    shuffled[i] = t;
  }
  return shuffled;
};

const guessComparator = (guess: string, answer: string): number => {
  return editDistance(guess.toUpperCase().replace(/[^A-Z]/g, ""), answer.toUpperCase().replace(/[^A-Z]/g, ""));
};

const LeaderboardEntry = ({
  current,
  // TODO: this is currently unused, but will be useful when we connect
  // to other players via WebRTC.
  connected,
  me,
  user,
  score,
  done,
  removePlayer,
  creator
}: {
  current: boolean;
  connected: boolean;
  me: boolean;
  done: boolean;
  user: User;
  score: number;
  removePlayer: () => void;
  creator: boolean;
}) => (
    <span
      className={classNames({
        [styles.leaderboardentry]: true,
        [styles.currentplayer]: current && (connected || me),
        [styles.doneplayer]: done && (connected || me),
        [styles.connectedplayer]: !done && (connected || me),
      })}
    >
      <span className={styles.leaderboardavatar}>
        <Avatar alt={user.name} src={user.photo} />
      </span>
      <span className={styles.leaderboardname}>{user.name?.split(" ")[0]}</span>
      <span className={styles.spacer}></span>
      <span className={styles.leaderboardscore}>{score}</span>
      {(me || creator) && <span className={styles.leaderboardremove}>
        <ToggleButtonGroup>
          <ToggleButton value="clear" onClick={removePlayer}>
            <DeleteForever />
          </ToggleButton>
        </ToggleButtonGroup>
        </span>}
</span>
  );

class Sketch extends Game<Props> {
  static defaultProps: GameState = {
    game: "sketch",
    title: "Just a sketchpad",
    description: "Draw away :)",
    started: false,
    creator: "",
    loaded: false,
    players: [],
    auth: {},
    pendingActions: false,
  };

  handleResize = () => {
    // Dispatching this local action will induce a render. The render function
    // will then cause resizeCanvas to be called when it encounters new
    // dimensions for the canvas.
    dispatchLocalAction(
      new actions.ResizeCanvasAction(window.innerWidth, window.innerHeight)
    );
  };

  rafToken: number = 0;
  private raf() {
    this.rafToken = window.requestAnimationFrame(this.handleDraw);
  }

  private cancelRaf() {
    window.cancelAnimationFrame(this.rafToken);
  }

  startTime: number = 0;
  frameNumber: number = 0;
  drawTime: number = 0;
  firstFrame = true;
  segmentCount = 0;

  drawingStarted() {
    let len = this.props.segment.length;
    return (
      len !== 0 &&
      this.props.segment[len - 1].points?.length > 0 &&
      this.props.wordChoices.length === 0
    );
  }

  handleDraw = () => {
    // how many frames' worth of data before hitting the server.
    const FRAMES_TO_ACCUMULATE = 6;
    let millis = performance.now();
    if (this.startTime === 0) {
      this.startTime = millis - 1;
    }
    let g = this.getCanvas();
    if (g) {
      let ctx = g.getContext("2d");
      if (ctx) {
        let redraw = this.firstFrame || !this.drawingStarted();
        if (redraw) {
          this.firstFrame = false;
          this.redraw();
        }
        let segment = this.currentSegment;
        if (segment.points.length) {
          this.drawSegment(segment);
        }
        ctx.font = "12px serif";
        if (this.frameNumber % FRAMES_TO_ACCUMULATE === 0) {
          if (this.currentSegment.points.length > 1 && this.playerMayDraw()) {
            this.commitRealtimeAction(new DrawPointsAction(segment));
            this.segmentCount++;
            this.currentSegment.points = [
              segment.points[segment.points.length - 1],
            ];
          }
        }
        if (
          this.segmentCount > 20 &&
          !this.isScribble() &&
          this.isCurrentPlayer()
        ) {
          this.segmentCount = 0;
          this.commitAction(new NextPlayerAction());
        }
        if (
          this.frameNumber % 60 === 0 &&
          this.drawingStarted() &&
          this.isCurrentPlayer()
        ) {
          const guessersRemaining =
            Object.keys(this.props.playersRemaining).length - 1;
          if (this.props.timeRemaining < 0 || guessersRemaining === 0) {
            if (!this.props.clearInFlight) {
              dispatchLocalAction(new ClearInFlightAction());
              this.clearRealtimeActions().then(() => {
                this.currentSegment.points = [];
                this.commitAction(new actions.NextDrawingAction());
              });
            }
          } else {
            if (this.isScribble()) {
              this.commitRealtimeAction(
                new actions.TickAction(this.props.timeRemaining - 1)
              );
            }
          }
        }
        /*
        if (this.frameNumber % 60 === 0) {
          let elapsed = millis - this.startTime;
          this.drawTime += (performance.now() - millis) * 1000000;
          ctx.fillStyle = "yellow";
          ctx.fillRect(0, 0, 80, 60);
          ctx.fillStyle = "black";
          ctx.fillText("frame: " + this.frameNumber, 5, 15);
          let fps = elapsed / this.frameNumber;
          fps = Math.round(1000 / fps);
          ctx.fillText("FPS: " + fps, 5, 30);
          ctx.fillText(
            "ns/f: " + Math.floor(this.drawTime / this.frameNumber),
            5,
            45
          );
        }
        */
      }
    }
    this.frameNumber++;
    this.raf();
  };

  currentSegment: SegmentState = {
    player: -1,
    points: [],
    color: "black",
    width: 4,
  };
  positionHandler = (e: any) => {
    e.preventDefault();
    if (!this.playerMayDraw() || this.props.clearInFlight) {
      return;
    }
    if (this.isChoosingWord()) {
      // don't let the player draw during word choice.
      return;
    }
    this.currentSegment.player = this.props.currentPlayer;
    if (e.clientX && e.clientY && e.buttons !== 0) {
      const canvasPosition = e.target.getBoundingClientRect();
      this.currentSegment.points.push({
        x: (e.clientX - canvasPosition.left) / e.target.clientWidth,
        y: (e.clientY - canvasPosition.top) / e.target.clientHeight,
      });
    } else if (e.touches && e.touches.length > 0 && e.type !== "touchend") {
      const canvasPosition = e.target.getBoundingClientRect();
      this.currentSegment.points.push({
        x: (e.touches[0].clientX - canvasPosition.left) / e.target.clientWidth,
        y: (e.touches[0].clientY - canvasPosition.top) / e.target.clientHeight,
      });
    } else if (this.currentSegment.points.length > 0) {
      this.commitRealtimeAction(new DrawPointsAction(this.currentSegment));
      this.segmentCount++;
      this.currentSegment.points = [];
    }
    if (
      this.currentSegment.points.length > 0 &&
      this.currentSegment.width === 0
    ) {
      // don't let the user draw with the fill brush
      this.commitRealtimeAction(new DrawPointsAction(this.currentSegment));
      this.segmentCount++;
      this.currentSegment.points = [];
    }
  };
  preventDefault = (e: any) => {
    e.preventDefault();
  };

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("resize", this.handleResize);
    this.raf();
  }

  canvas: RefObject<HTMLCanvasElement> = React.createRef();
  canvasContainer?: HTMLDivElement;
  getCanvas(): HTMLCanvasElement | null {
    return this.canvas.current;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("resize", this.handleResize);
    this.cancelRaf();
  }

  createSetupAction(): AnyAction {
    if (this.props.variant === "Scribble") {
      let shuffled = shuffle(words);
      return new actions.ShuffleWordsAction(this.props.variant, shuffled);
    }
    let shuffled = shuffle(catWords);
    return new actions.ShuffleWordsAction(this.props.variant, shuffled);
  }

  newGame = (): void => {
    if (this.isCurrentPlayer()) {
      let shuffledPlayers = shuffle(this.props.orderedPlayers);
      const mrwhite =
        Math.floor(Math.random() * (this.props.orderedPlayers.length - 1)) + 1;
      if (this.isScribble()) {
        this.commitAction(new actions.NewGameAction(mrwhite, shuffledPlayers));
      } else {
        this.clearRealtimeActions().then(() => {
          this.currentSegment.points = [];
          this.commitAction(new actions.NewGameAction(mrwhite, shuffledPlayers));
        });
      }
    }
  };

  clearDrawing = (): void => {
    if (this.playerMayDraw()) {
      let empty: SegmentState = {
        player: this.props.currentPlayer,
        points: [],
        color: "white",
        width: 0,
      };
      this.commitRealtimeAction(new actions.DrawPointsAction(empty));
    }
  };

  sameColor(c1: number, c2: number): boolean {
    return c1 === c2 || !this.reverseColorMap[c1];
  }

  // Flood fill is based on the algorithm here
  // http://www.williammalone.com/articles/html5-canvas-javascript-paint-bucket-tool/
  // referenced from here https://ben.akrin.com/?p=7888
  // and modified for performance via cache coherency and single-value colors
  // rather than four bytes per colour.
  floodFill(color: string, x: number, y: number): void {
    //let millis = performance.now();
    let g = this.getCanvas();
    const ctx = g?.getContext("2d");
    if (!g || !ctx) return;
    const width = g.width;
    const height = g.height;
    const bytePixels = ctx.getImageData(0, 0, width, height);
    const intPixels = new Uint32Array(bytePixels.data.buffer);
    const index = (x: number, y: number): number => y * width + x;
    const ix = Math.round(x * width);
    const iy = Math.round(y * height);
    const stack = [index(ix, iy)];
    const fillOver = intPixels[stack[0]];
    const fillColor = this.colorMap[color];
    if (this.sameColor(fillColor, fillOver)) {
      return;
    }
    let count = 0;
    let maxStack = 0;
    while (stack.length && count < 5000) {
      count++;
      maxStack = Math.max(maxStack, stack.length);
      let next = stack.pop();
      if (next === null || next === undefined) continue;
      // find the beginning of this run's fillOver color
      while (
        next % width > 0 &&
        this.sameColor(intPixels[next - 1], fillOver)
      ) {
        next--;
      }
      // work forward from there, noticing for the rows above and below
      // which new pixels need to be on the stack, and filling as we go.
      let rowAbove = next - width;
      let seenAbove = false;
      let rowBelow = next + width;
      let seenBelow = false;
      do {
        intPixels[next] = fillColor;
        if (intPixels[next] !== fillColor) {
          console.error("WTF?", next, intPixels[next], fillColor);
          return;
        }
        if (rowAbove >= 0) {
          if (this.sameColor(intPixels[rowAbove], fillOver)) {
            if (!seenAbove) {
              seenAbove = true;
              stack.push(rowAbove);
            }
          } else {
            seenAbove = false;
          }
          rowAbove++;
        }
        if (rowBelow < intPixels.length) {
          if (this.sameColor(intPixels[rowBelow], fillOver)) {
            if (!seenBelow) {
              seenBelow = true;
              stack.push(rowBelow);
            }
          } else {
            seenBelow = false;
          }
          rowBelow++;
        }
        next++;
      } while (next % width !== 0 && this.sameColor(intPixels[next], fillOver));
    }

    if (stack.length) {
      stack.forEach((x) => {
        intPixels[x] = 0xff0000ff;
      });
    }
    const bytes = new Uint8ClampedArray(intPixels.buffer);
    ctx.putImageData(new ImageData(bytes, width, height), 0, 0);
    //let end = performance.now();
    //console.error("Millis for fill: " + (end - millis) + " iters: " + count + " maxS: " + maxStack);
    //console.error("fillColor ", fillColor, " fillOver ", fillOver, " stack ", stack, width, ix, iy);
  }
  drawSegment(segment: SegmentState, color?: string): ReactNode {
    let g = this.getCanvas();
    let points = segment?.points || [];
    if (segment.player !== this.props.currentPlayer) return null;
    if (g) {
      let ctx = g.getContext("2d");
      if (ctx) {
        if (points.length === 0) {
          ctx.canvas.width = g.width;
          return null;
        }
        if (points.length === 1 && segment.width === 0) {
          this.floodFill(color || segment.color, points[0].x, points[0].y);
          return null;
        }
        ctx.strokeStyle = color || segment.color;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
        ctx.lineWidth = (segment.width * g.height) / 600;
        ctx.beginPath();
        if (points.length) {
          ctx.moveTo(points[0].x * g.width, points[0].y * g.height);
          if (points.length === 1) {
            ctx.lineTo(points[0].x * g.width + 1, points[0].y * g.height);
          }
          for (let i = 1; i < points.length; ++i) {
            ctx.lineTo(points[i].x * g.width, points[i].y * g.height);
          }
          ctx.stroke();
        }
      }
    }
    return null;
  }

  draw(): void {
    const props = this.props;
    const last = props.segment.length - 1;
    if (last >= 0) {
      this.drawSegment(props.segment[last]);
    }
  }
  redraw(): void {
    // search for the last occurrence of [] which marks a
    // drawing boundary, and then draw from then on.
    if (!this.props.segment || !this.props.segment.length) return;
    let s = this.props.segment.length - 1;
    while (this.props.segment[s].points?.length > 0 && s > 0) {
      s--;
    }
    while (s < this.props.segment.length) {
      this.drawSegment(this.props.segment[s++]);
    }
  }
  colorMap: { [key: string]: number } = {};
  reverseColorMap: { [key: number]: string } = {};
  recordColor = (swatch: HTMLSpanElement): void => {
    const name = swatch.id;
    const color = window.getComputedStyle(swatch).borderColor;
    const carray = color.match(/[.\d]+/g)?.map((a) => +a) || [0, 0, 0];
    let number = 0;
    number = carray[0] | (carray[1] << 8) | (carray[2] << 16);
    if (color.indexOf("rgba") === -1) {
      number = (number | 0xff000000) >>> 0;
    } else {
      number = (number | (carray[3] << 24)) >>> 0;
    }
    this.colorMap[name] = number;
    this.reverseColorMap[number] = name;
  };
  updateCanvas = (container: HTMLDivElement): void => {
    this.canvasContainer = container;
    let canvas = this.getCanvas();
    if (!canvas) {
      console.error("No canvas element when rendering container");
      return;
    }
    this.resizeCanvas(container.clientWidth, container.clientHeight);

    canvas.addEventListener("mousedown", this.positionHandler);
    canvas.addEventListener("mousemove", this.positionHandler);
    canvas.addEventListener("mouseup", this.positionHandler);
    canvas.addEventListener("touchstart", this.positionHandler);
    canvas.addEventListener("touchmove", this.positionHandler);
    canvas.addEventListener("touchend", this.positionHandler);
    canvas.addEventListener("contextmenu", this.positionHandler);
  };

  private resizeCanvas(width: number, height: number) {
    let canvas = this.getCanvas();
    if (!canvas) return;
    let cssPxSize = Math.min(width, height) - 50;
    let pixels = cssPxSize * window.devicePixelRatio;
    canvas.style.left = (width - cssPxSize) / 2 + "px";
    canvas.style.top = (height - cssPxSize) / 2 + "px";
    canvas.setAttribute("width", "" + pixels);
    canvas.setAttribute("height", "" + pixels);
    canvas.style.width = canvas.style.height = cssPxSize + "px";
  }

  playerMayTalk(email?: string): boolean {
    return !this.isCurrentPlayer(email);
  }
  playerMaySeeClue(email?: string): boolean {
    return this.isCurrentPlayer(email);
  }
  playerMayDraw(email?: string): boolean {
    return this.isCurrentPlayer(email) || !this.isScribble();
  }
  isCurrentPlayer(email?: string): boolean {
    if (!email) email = this.props.auth.email || "";
    return (
      this.props.currentPlayer === this.props.orderedPlayers.indexOf(email)
    );
  }
  isCreator(email?: string): boolean {
    if (!email) email = this.props.auth.email || "";
    return this.props.creator === email;
  }

  isInOrderedPlayers(email?: string): boolean {
    if (!email) email = this.props.auth.email || "";
    return this.props.orderedPlayers.indexOf(email) !== -1;
  }

  isRemovedPlayer(email?: string): boolean {
    if (!email) email = this.props.auth.email || "";
    return this.props.removedPlayers[email] !== undefined;
  }

  isMrWhite(email?: string): boolean {
    if (!email) email = this.props.auth.email || "";
    return this.props.mrwhite === this.props.orderedPlayers.indexOf(email);
  }

  formatWord(word: string, email?: string): string {
    if (this.isScribble()) {
      return word;
    }
    const catWord = word.split("|");
    const w = this.isMrWhite(email) ? "^^" : catWord[1];
    return catWord[0] + ": " + w;
  }
  getWord(): string {
    if (this.props.isGameOver) {
      return (
        "The last word was: " +
        this.formatWord(this.props.currentWord, "reveal") +
        ". Game Over!"
      );
    }
    if (this.props.wordChoices.length) {
      if (!this.props.isGameOver) {
        return (
          "That word was: " + this.formatWord(this.props.currentWord, "reveal")
        );
      }
      return "Game is about to start!";
    }
    if (!this.isScribble()) {
      return this.formatWord(this.props.currentWord);
    }
    if (this.playerMaySeeClue()) {
      return this.props.currentWord;
    }
    const upper = this.props.currentWord.toUpperCase();
    return upper.replace(/ /g, " ").replace(/[A-Z]/g, " ＿  ");
  }

  gotItSound = new Audio("/gotit.mp3");
  yourTurnSound = new Audio("/yourturn.m4a");
  guess = (input: string): string => {
    if (input.indexOf("add color") === 0 || input.indexOf("add colour") === 0) {
      let split = input.split(" ");
      if (split.length === 3) {
        this.commitPreAction(new CustomColorAction(split[2]));
        return input;
      }
    }

    if (input.indexOf("kick player ") === 0) {
      let split = input.split(" ");
      if (split.length === 3) {
        this.commitAction(new RemovePlayerAction(split[2]));
        return input;
      }
    }

    if (input.indexOf("next") !== -1 && !this.isScribble()) {
      this.newGame();
      return input;
    }
    if (!this.playerMayTalk()) {
      return "** No Talking! **";
    }
    let distance = guessComparator(input, this.props.currentWord);
    if (distance === 0 || (distance <= 2 && (this.props.currentWord.length - distance > 5))) {
      if (this.props.auth.email) {
        if (this.props.orderedPlayers.indexOf(this.props.auth.email) >= 0) {
          this.commitAction(
            new actions.ScoreAction(
              this.props.auth.email,
              this.props.timeRemaining
            )
          );
        }
      }
      if (this.gotItSound) {
        this.gotItSound.play();
      }
      return "Got it!";
    }
    /*
    if (distance < 4 && this.props.currentWord.length > 5) {
      return "Close!";
    }
    */
    return input;
  };

  removePlayer = (email: string) => {
    this.guess("kick player " + email);
  }
  brushSize = (s: any, size: string) => {
    if (!this.playerMayDraw()) {
      // disallow other players from mucking with your pen.
      return;
    }
    if (!size) {
      return;
    }
    this.currentSegment.width = parseInt(size);
    this.commitRealtimeAction(new PenWidthAction(this.currentSegment.width));
  };

  chooseWord = (s: any, word: string) => {
    this.commitAction(new ChooseWordAction(word));
  };

  penColor = (s: any, color: string) => {
    if (!this.playerMayDraw()) {
      // disallow other players from mucking with your pen.
      return;
    }
    if (!color) {
      console.error("Got an undefined pen color!");
      color = "black";
    }
    this.currentSegment.color = color;
    this.commitRealtimeAction(new PenColorAction(color));
  };

  isChoosingWord(): boolean {
    return this.props.wordChoices.length > 0 && this.isCurrentPlayer();
  }

  isScribble(): boolean {
    return this.props.variant === "Scribble";
  }

  lastSize = 0;
  lastPlayer = -1;
  renderGame(): ReactNode {
    const props = this.props;
    const leaderboard: [number, string][] = [];
    if (
      this.lastPlayer !== this.props.currentPlayer &&
      this.isCurrentPlayer()
    ) {
      if (this.yourTurnSound) {
        this.yourTurnSound.play();
        this.segmentCount = 0;
      }
    }
    this.lastPlayer = this.props.currentPlayer;
    if (this.isScribble()) {
      for (var key in this.props.leaderboard) {
        leaderboard.push([this.props.leaderboard[key], key]);
      }
      leaderboard.sort((a, b) => b[0] - a[0]);
    } else {
      this.props.orderedPlayers.forEach((p) =>
        leaderboard.push([this.props.leaderboard[p], p])
      );
    }
    if (!this.isInOrderedPlayers() && !this.isRemovedPlayer() && this.props.auth.email && this.props.code) {
      this.dispatch(new LateJoinAction(this.props.auth.email));
      this.commitAction(new LateJoinAction(this.props.auth.email));
    }
    const isGameOver = this.props.isGameOver;
    const wordChoiceDisplay = this.isChoosingWord();
    if (wordChoiceDisplay && !this.isScribble()) {
      this.firstFrame = true;
    }
    if (this.lastSize !== props.width * props.height) {
      this.lastSize = props.width * props.height;
      this.firstFrame = true;
      if (this.canvasContainer) {
        this.resizeCanvas(
          this.canvasContainer.clientWidth,
          this.canvasContainer.clientHeight
        );
      }
    }

    if (this.props.penColor !== this.currentSegment.color) {
      this.currentSegment.color = this.props.penColor;
    }
    if (this.props.penWidth !== this.currentSegment.width) {
      this.currentSegment.width = this.props.penWidth;
    }

    return (
      <div className={styles.container}>
        <div className={styles.left}>
          {leaderboard.map((entry) => (
            <LeaderboardEntry
              key={entry[1]}
              current={this.isCurrentPlayer(entry[1])}
              me={entry[1] === this.props.auth.email}
              connected={true}
              done={!this.props.playersRemaining[entry[1]]}
              user={this.props.users.users[entry[1]]}
              score={entry[0]}
              removePlayer={() => this.removePlayer(entry[1])}
              creator={this.isCreator()}
            />
          ))}
        </div>
        <div className={styles.canvascolumn}>
          {wordChoiceDisplay && (
            <ToggleButtonGroup onChange={this.chooseWord} exclusive>
              {props.wordChoices.map((w) => (
                <ToggleButton key={w} value={w}>
                  {this.formatWord(w)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
          <div className={styles.prompt}>{this.getWord()}</div>
          {isGameOver || wordChoiceDisplay || (
            <div className={styles.prompt}>
              Round #{NUM_ROUNDS - this.props.roundNumber} of {NUM_ROUNDS}:{" "}
              {this.props.timeRemaining}s
            </div>
          )}
          {isGameOver && this.isCurrentPlayer() && !wordChoiceDisplay && (
            <Button variant="contained" onClick={this.newGame}>
              New Game
            </Button>
          )}
          <div ref={this.updateCanvas} className={styles.pageborder}>
            <canvas
              ref={this.canvas}
              width={props.width}
              height={props.height}
            />
            {this.draw()}
          </div>
          <div className={styles.toolbar}>
            <ToggleButtonGroup
              value={"" + props.penWidth}
              exclusive
              onChange={this.brushSize}
            >
              <ToggleButton value="15">
                <span className={styles.bigBrush} />
              </ToggleButton>
              <ToggleButton value="10">
                <span className={styles.midBrush} />
              </ToggleButton>
              <ToggleButton value="4">
                <span className={styles.penBrush} />
              </ToggleButton>
              <ToggleButton value="0">
                <FormatColorFill />
              </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
              value={"" + props.penColor}
              exclusive
              onChange={this.penColor}
            >
              {[
                "black",
                "red",
                "blue",
                "green",
                "yellow",
                "brown",
                "white",
              ].map((c) => {
                return (
                  <ToggleButton key={c} value={c}>
                    <span
                      ref={this.recordColor}
                      id={c}
                      className={styles.swatch}
                      style={{
                        borderColor: c,
                      }}
                    />
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
            <ToggleButtonGroup>
              <ToggleButton value="clear" onClick={this.clearDrawing}>
                <DeleteForever />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div>
            <ToggleButtonGroup
              value={"" + props.penColor}
              exclusive
              onChange={this.penColor}
            >
              {this.props.customColors.map((c) => {
                return (
                  <ToggleButton key={c} value={c}>
                    <span
                      ref={this.recordColor}
                      id={c}
                      className={styles.swatch}
                      style={{
                        borderColor: c,
                      }}
                    />
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
            {this.props.segment.length}
          </div>
        </div>
        <div className={styles.right}>
          <Chat
            inputTransform={this.guess}
            room={this.props.game + ":" + this.props.code + ":chats"}
          />
        </div>
      </div>
    );
  }

  setVariant = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      this.dispatch(new SetSketchVariantAction(e.target.value));
    }
  };
  renderGameSettings(): ReactNode {
    return (
      <div>
        <h1>Settings</h1>
        <div>
          <FormControl>
            <NativeSelect
              value={this.props.variant}
              onChange={this.setVariant}
              inputProps={{
                name: "variation",
                id: "variation-native-helper",
              }}
            >
              {["Scribble", "Undercover"].map((key: string) => (
                <option key={key} value={key}>
                  {key}
                </option>
              ))}
            </NativeSelect>
            <FormHelperText>Choose variation</FormHelperText>
          </FormControl>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapPropsToDispatch)(Sketch);
