export const editDistance = (strA: string, strB: string): number => {
  let distance: number[][] = [];
  for (let i = 0; i <= strA.length; i++) {
    distance[i] = [];
    distance[i][0] = i;
  }
  for (let i = 0; i <= strB.length; i++) {
    distance[0][i] = i;
  }

  for (let i = 1; i <= strA.length; i++) {
    for (let j = 1; j <= strB.length; j++) {
      if (strA[i - 1] === strB[j - 1]) {
        distance[i][j] = distance[i-1][j-1];
      } else {
        distance[i][j] = Math.min(
          distance[i - 1][j] + 1,
          distance[i][j - 1] + 1,
          distance[i - 1][j - 1] + 1, // substitution
        );
      }
    }
  }
  return distance[strA.length][strB.length];
};
