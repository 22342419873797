import React from "react";
import { Component, ReactNode } from "react";
import * as types from "../routes/chat/reducers";
import { User } from "../routes/users/reducers";
import styles from "./chatmessage.module.css";
import { Avatar } from "@material-ui/core";
import { AuthState } from "../routes/login/reducers";
import classNames from "classnames";

type Props = {
  messages: types.ChatMessage[];
  user: User;
  currentUser: AuthState;
};

class ChatMessage extends Component<Props> {
  render(): ReactNode {
    const props = this.props;
    const now = new Date().getTime();
    const elapsedSeconds =
      (now - props.messages[props.messages.length - 1].timestamp) / 1000;
    let timeString = "Long ago";
    if (elapsedSeconds < 60) {
      timeString = "Now";
    } else if (elapsedSeconds < 3600) {
      timeString = Math.floor(elapsedSeconds / 60) + "m ago";
    }
    const isCurrentUser = props.currentUser.email === props.messages[0].email;
    return (
      <div className={styles.outer}>
        <div
          className={classNames({
            [styles.container]: true,
            [styles.currentUser]: isCurrentUser,
          })}
        >
          {!isCurrentUser && (
            <Avatar alt={props.user.name} src={props.user.photo} />
          )}
          <div className={styles.messages}>
            {props.messages.map(message => {
              return (
                <div
                  className={classNames({ [styles.pending]: message.pending })}
                  key={message.timestamp}
                >
                  {message.text}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.author}>
          {props.user.name} ({props.messages[0].email}) {timeString}
        </div>
      </div>
    );
  }
}

export default ChatMessage;
