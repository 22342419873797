import React, { ChangeEvent, Fragment } from "react";
import { Component, ReactNode } from "react";
import * as actions from "./actions";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction } from "redux";
import { MahjongState, PlayerStateMap } from "./reducers";
import { State, store } from "../../store";
import { shuffle } from "../../components/shuffle";

import classNames from "classnames";
import tabletops from "../../components/tabletops.module.css";
import styles from "./style.module.css";

import { Game, GameState } from "../../components/game";
import { AuthState } from "../login/reducers";
import { Users } from "../users/reducers";
import {
  Button,
  Avatar,
  FormControl,
  NativeSelect,
  FormHelperText,
} from "@material-ui/core";
import { SortableElement, SortableContainer } from "react-sortable-hoc";

import hongkong from "./mahjong.module.css";
import newhong from "./hongkong2.module.css";
import american from "./american.module.css";
import changsha from "./changsha.module.css";
import changshae from "./changshae.module.css";
import {
  DiscardTileAction,
  UpdateRackAction,
  PlayerOrderAction,
  DrawTileAction,
  TakeDiscardedTileAction,
} from "./actions";
import { Tile } from "../../components/tiles";
import { SortableTile } from "../../components/sortabletile";

const tilesets: { [key: string]: { tiles: {}; name: string } } = {
  hongkong: {
    tiles: hongkong,
    name: "Hong Kong",
  },
  newhong: {
    tiles: newhong,
    name: "Hong Kong 2",
  },
  american: {
    tiles: {...newhong, ...american },
    name: "American",
  },
  changsha: {
    tiles: changsha,
    name: "Changsha",
  },
  changshae: {
    tiles: changshae,
    name: "Changsha (English)",
  },
};

const tiles = { ...hongkong, ...changsha, ...changshae, ...newhong, ...american };

const mapStateToProps = (
  state: State
): MahjongState & { auth: AuthState } & { users: Users } => ({
  ...state.mahjong,
  auth: state.auth,
  users: state.users,
});
const mapPropsToDispatch = actions;

const connector = connect(mapStateToProps, mapPropsToDispatch);
type Props = ConnectedProps<typeof connector> & GameState;

type RackProps = {
  rack: number[];
  melds: number[];
  deck?: string[];
};

const RackDivider = SortableElement(() => {
  return <span className={styles.divider} key="divider" />;
});

class Rack extends Component<RackProps> {
  render(): ReactNode {
    const deck = this.props.deck || [];
    const rack = this.props.rack;
    const melds = this.props.melds;
    let i = 0;
    return (
      <div className={styles.rack}>
        <div>
          {melds.map((tilenumber: number) => {
            const tilename = deck[tilenumber];
            const tile = tiles[tilename];
            return (
              <SortableTile className={styles.glossytile}
                key={tile + tilenumber}
                index={i++}
                name={tile}
              />
            );
          })}
        </div>
        <div>
          <RackDivider index={i++} />
          {rack.map((tilenumber: number) => {
            const tilename = deck[tilenumber];
            const tile = tiles[tilename];
            return (
              <SortableTile className={styles.glossytile}
                key={tile + tilenumber}
                index={i++}
                name={tile}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const SortableRack = SortableContainer(Rack);

class Mahjong extends Game<Props> {
  static defaultProps: GameState = {
    game: "mahjong",
    title: "Mahjong",
    description: "Enjoy any Mahjong variation with your friends!",
    started: false,
    loaded: false,
    creator: "",
    players: [],
    auth: {},
    pendingActions: true,
  };
  onSortEnd = async (params: any) => {
    const props = this.props;
    const me = props.auth.email || "";
    if (!props.playerState) return;
    console.log(JSON.stringify(props.playerState[me]));
    const melds = props.playerState[me].melds;
    const rack = props.playerState[me].rack;
    let discards: number[] = [];
    if (props.discards) {
      discards = [...props.discards];
    }

    let newMelds = [...melds];
    let newRack = [...rack];
    let value = 0;
    let fromMelds = false;

    if (params.oldIndex < melds.length) {
      fromMelds = true;
      value = newMelds.splice(params.oldIndex, 1)[0];
    } else {
      let index = params.oldIndex - melds.length - 1;
      value = newRack.splice(index, 1)[0];
    }

    let isDiscarding = false;
    if (params.newIndex !== params.oldIndex) {
      if (params.newIndex < melds.length + 1) {
        newMelds.splice(params.newIndex, 0, value);
        console.log("To MELD: " + params.newIndex);
      } else {
        let index = params.newIndex - melds.length - (fromMelds ? 0 : 1);
        newRack.splice(index, 0, value);
        console.log("To RACK: " + index);
      }
    } else {
      isDiscarding = true;
      discards.push(value);
    }

    let playerState: PlayerStateMap = { ...this.props.playerState };
    playerState[me] = {
      rack: newRack,
      melds: newMelds,
    };
    if (isDiscarding) {
      this.commitAction(new DiscardTileAction(me, playerState[me], value));
    } else {
      this.commitAction(new UpdateRackAction(me, playerState[me]));
    }
  };
  createSetupAction(): AnyAction {
    const deck: string[] = [];
    let tileset = tilesets[this.props.tileset].tiles;
    console.log("tileset has ", Object.keys(tileset));
    Object.keys(tileset).map((tilename) => {
      if (tilename.startsWith("z_joker")) {
        deck.push(tilename);
        deck.push(tilename);
        deck.push(tilename);
        deck.push(tilename);
        deck.push(tilename);
        deck.push(tilename);
        deck.push(tilename);
        deck.push(tilename);
      } else if (tilename.startsWith("aflower")) {
        deck.push(tilename);
      } else {
        deck.push(tilename);
        deck.push(tilename);
        deck.push(tilename);
        deck.push(tilename);
      }
      return tilename;
    });
    console.log("Game setup with " + deck.length + " tiles");
    return actions.setFieldsAction({
      deck: shuffle(deck),
      top: 0,
      tileset: this.props.tileset,
      players: [],
    });
  }
  gameWillStart() {
    let players = shuffle(this.props.players);
    this.commitAction(new PlayerOrderAction(players));
  }

  drawTile = async (): Promise<void> => {
    if (!this.props.auth.email) {
      // TODO: fail in some visible way
      console.log("ERROR: player is not signed in");
      return;
    }

    this.commitAction(new DrawTileAction(this.props.auth.email));
  };

  sort = async (): Promise<void> => {
    if (!this.props.auth.email) {
      // TODO: fail in some visible way
      console.log("ERROR: player is not signed in");
      return;
    }

    let playerState: PlayerStateMap = { ...this.props.playerState };
    const me = this.props.auth.email;
    const rack = [...playerState[me].rack];
    const deck = this.props.deck || [];
    rack.sort(function(a, b) {
      const sa = deck[a];
      const sb = deck[b];
      if (sa < sb) {
        return -1;
      }
      if (sa > sb) {
        return 1;
      }
      return 0;
    });
    playerState[me] = {
      rack: rack,
      melds: playerState[me].melds,
    };
    this.commitAction(new UpdateRackAction(me, playerState[me]));
  };

  mahjong = async (): Promise<void> => {
    if (!this.props.auth.email) {
      // TODO: fail in some visible way
      console.log("ERROR: player is not signed in");
      return;
    }

    let playerState: PlayerStateMap = { ...this.props.playerState };
    const me = this.props.auth.email;
    const rack = [...playerState[me].rack];
    playerState[me] = {
      rack: [],
      melds: [...playerState[me].melds, ...rack],
    };
    this.commitAction(new UpdateRackAction(me, playerState[me]));
  };

  takeDiscard = async (): Promise<void> => {
    if (!this.props.auth.email) {
      // TODO: fail in some visible way
      console.log("ERROR: player is not signed in");
      return;
    }
    const me = this.props.auth.email;
    this.commitAction(new TakeDiscardedTileAction(me));
  };

  setTileset = (e: ChangeEvent<HTMLSelectElement>) => {
    store.dispatch(
      actions.setFieldsAction({ ...this.props, tileset: e.target.value })
    );
  };

  renderGameSettings(): ReactNode {
    return (
      <div>
        <h1>Settings</h1>
        <div>
          <FormControl>
            <NativeSelect
              value={this.props.tileset}
              onChange={this.setTileset}
              inputProps={{
                name: "variation",
                id: "variation-native-helper",
              }}
            >
              <option aria-label="None" value="" />
              {Object.keys(tilesets).map((key: string) => (
                <option key={key} value={key}>
                  {tilesets[key].name}
                </option>
              ))}
            </NativeSelect>
            <FormHelperText>Choose your variation (tiles)</FormHelperText>
          </FormControl>
        </div>
      </div>
    );
  }
  renderMelds(playerIndex: number): ReactNode {
    const props = this.props;
    const deck = this.props.deck || [];
    const player = props.players[playerIndex];
    let user = props.users.users[player];
    if (playerIndex < 0) {
      return <Fragment />;
    }
    return (
      <Fragment key={player}>
        <Avatar key="avatar" alt={user.name} src={user.photo} />
        {props.playerState &&
          props.playerState[player].melds.map((tilenumber) => {
            const tilename = deck[tilenumber];
            const tile = tiles[tilename];
            return <Tile className={styles.glossytile} name={tile} />;
          })}
      </Fragment>
    );
  }
  renderGame(): ReactNode {
    const props = this.props;
    const deck = this.props.deck || [];

    let me = this.props.auth.email || "";
    let bottomPlayer = props.players.indexOf(me);
    if (bottomPlayer < 0) {
      bottomPlayer = 0;
      me = props.players[bottomPlayer];
    }
    let leftPlayer = (bottomPlayer + 1) % props.players.length;
    let topPlayer = (leftPlayer + 1) % props.players.length;
    let rightPlayer = (topPlayer + 1) % props.players.length;
    if (leftPlayer === bottomPlayer) {
      leftPlayer = topPlayer = rightPlayer = -1;
    }
    if (topPlayer === bottomPlayer) {
      topPlayer = rightPlayer = -1;
    }
    if (rightPlayer === bottomPlayer) {
      rightPlayer = -1;
    }
    return (
      <div className={classNames(styles.green, tabletops.felt)}>
        <h2>Mahjong Game Code: {props.code}</h2>
        <div>
          <div className={styles.topMelds}>{this.renderMelds(topPlayer)}</div>
          <div className={styles.leftMelds}>{this.renderMelds(leftPlayer)}</div>
          <div className={styles.rightMelds}>
            {this.renderMelds(rightPlayer)}
          </div>
          <div className={styles.discards}>
            <p>Discarded Tiles</p>
            {props.discards &&
              props.discards.map((tilenumber) => {
                const tilename = deck[tilenumber];
                const tile = tiles[tilename];
                return <Tile key={tile + tilenumber} className={styles.glossytile} name={tile} onClick={this.takeDiscard} />;
              })}
          </div>
        </div>
        {props.playerState && (
          <SortableRack
            {...props.playerState[me]}
            axis="xy"
            deck={this.props.deck}
            onSortEnd={this.onSortEnd}
          />
        )}
        <div className={styles.rack}>
          <Button variant="contained" onClick={this.drawTile}>
            Draw
          </Button>
          <Button variant="contained" onClick={this.sort}>
            Sort
          </Button>
          <Button variant="contained" onClick={this.mahjong}>
            Mahjong
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapPropsToDispatch)(Mahjong);
