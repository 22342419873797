import { Reducer, AnyAction } from "redux";

export interface MahjongRack {
  rack: number[];
  melds: number[];
}

export interface PlayerStateMap {
  [id: string]: MahjongRack;
}

export interface MahjongState {
  players: string[];
  deck?: string[];
  top: number;
  playerState?: PlayerStateMap;
  discards?: number[];
  tileset: string;
  code?: string;
}

// mahjong
export const mahjong: Reducer<MahjongState> = (
  state = {
    tileset: "hongkong",
    deck: [],
    top: 0,
    players: [],
  },
  action: AnyAction
) => {
  let pending = true;
  if (action.timestamp && action.timestamp !== null) {
    pending = false;
  }
  switch (action.type) {
    case "SET_FIELDS":
      const fields = { ...action };
      delete fields.type;
      return { ...state, ...fields };

    case "TAKE_DISCARDED_TILE": {
      if (pending) return state;
      const ret = { ...state };
      let discards: number[] = [];
      if (ret.discards) {
        discards = [...ret.discards];
      }
      const taken = discards.splice(-1, 1);
      let playerState: PlayerStateMap = { ...ret.playerState };
      const me = { ...playerState[action.email] };
      playerState[action.email] = {
        rack: [...me.rack, taken[0]],
        melds: me.melds,
      };
      ret.playerState = playerState;
      ret.discards = discards;

      return ret;
    }
    case "DRAW_TILE": {
      if (pending) return state;
      const ret = { ...state };
      let playerState: PlayerStateMap = { ...ret.playerState };
      const me = { ...playerState[action.email] };
      playerState[action.email] = {
        rack: [...me.rack, ret.top],
        melds: me.melds,
      };
      ret.playerState = playerState;
      ret.top += 1;
      return ret;
    }
    case "UPDATE_RACK": {
      const ret = { ...state };
      ret.playerState = { ...state.playerState };
      ret.playerState[action.email] = action.rack;
      if (action.type === "DRAW_TILE") {
        ret.top = action.top;
      }
      return ret;
    }
    case "DISCARD_TILE": {
      const ret = { ...state };
      ret.playerState = { ...state.playerState };
      ret.playerState[action.email] = action.rack;
      if (!pending) {
        ret.discards = [...(ret.discards || []), action.discarded];
      }
      return ret;
    }
    case "JOIN_PLAYER": {
      if (pending) return state;
      if (action.game !== "mahjong" || action.code !== state.code) {
        return state;
      }

      let playerState: PlayerStateMap = { ...state.playerState };
      const me = action.email;
      // top is the top of the deck (next card to be drawn)
      // All cards are referred to by indexing into the deck with
      // integers that reflect their position in the original shuffle.
      console.log(state);
      let top = state.top;
      if (!playerState[me]) {
        const rack: number[] = [];
        for (let i = 0; i < 13; ++i) {
          rack.push(top);
          top += 1;
        }
        playerState[me] = {
          rack: rack,
          melds: [],
        };
      }
      return { ...state, playerState, top };
    }
    case "PLAYER_ORDER": {
      return { ...state, players: action.players };
    }
    default:
      return state;
  }
};
