import React, { Fragment } from "react";
import { ReactNode } from "react";
import * as actions from "./actions";
import {
  TendaysSetupAction,
  UpdateTendaysRackAction,
  DiscardTendaysTile,
  DrawTileAction,
} from "./actions";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction } from "redux";
import { TendaysState } from "./reducers";
import { PlayerStateMap } from "../mahjong/reducers";
import { State } from "../../store";

import classNames from "classnames";
import tabletops from "../../components/tabletops.module.css";
import styles from "./style.module.css";

import tiles from "./cards.module.css";

import { AuthState } from "../login/reducers";
import { Users } from "../users/reducers";

import { Game, GameState } from "../../components/game";
import { Tile } from "../../components/tiles";
import { Rack, Group } from "../../components/rack";

const mapStateToProps = (
  state: State
): TendaysState & { auth: AuthState } & { users: Users } => ({
  ...state.tendays,
  auth: state.auth,
  users: state.users,
});
const mapPropsToDispatch = actions;

const connector = connect(mapStateToProps, mapPropsToDispatch);
type Props = ConnectedProps<typeof connector> & GameState;

const DiscardPile = ({
  draw,
  deck,
  pile,
}: {
  draw: () => Promise<void>;
  deck: string[];
  pile: number[];
}) => {
  const tilename = deck[pile[pile.length - 1]];
  const tile = tiles[tilename];
  return (
    <Fragment>
      <div>
        <Tile className={styles.glossytile} name={tile} onClick={draw} />
      </div>
    </Fragment>
  );
};

class Tendays extends Game<Props> {
  static defaultProps: GameState = {
    game: "tendays",
    title: "Ten Days in Europe",
    description: "Travel through Europe in ten days, seeing all the sights!",
    started: false,
    loaded: false,
    creator: "",
    players: [],
    auth: {},
    pendingActions: false,
  };
  createSetupAction(): AnyAction {
    const deck: string[] = [];
    Object.keys(tiles).map((tilename) => {
      deck.push(tilename);
      return tilename;
    });

    const shuffle = (deck: string[]): string[] => {
      const shuffled = [...deck];
      for (let i = shuffled.length - 1; i > 0; --i) {
        const j = Math.floor(Math.random() * i);
        const t = shuffled[j];
        shuffled[j] = shuffled[i];
        shuffled[i] = t;
      }
      return shuffled;
    };

    return new TendaysSetupAction(shuffle(deck), 3, {}, [0], [1], [2]);
  }

  drawTile = async (): Promise<void> => {
    if (!this.props.auth.email) {
      // TODO: fail in some visible way
      console.log("ERROR: player is not signed in");
      return;
    }

    let playerState: PlayerStateMap = { ...this.props.playerState };
    const me = this.props.auth.email;
    let top = this.props.top || 0;
    const melds = [...playerState[me].melds];
    melds.push(top);
    top += 1;
    playerState[me] = {
      rack: playerState[me].rack,
      melds: melds,
    };
    this.commitAction(new DrawTileAction(me));
  };

  takeDiscard = async (pile: string): Promise<void> => {
    if (!this.props.auth.email) {
      // TODO: fail in some visible way
      console.log("ERROR: player is not signed in");
      return;
    }

    let playerState: PlayerStateMap = { ...this.props.playerState };
    const me = this.props.auth.email;
    let melds = [...playerState[me].melds];
    let discard: number[] = [];
    const props = this.props as any;
    if (props[pile]) {
      discard = [...props[pile]];
    }
    if (melds.length === 0) {
      const taken = discard.splice(-1, 1);
      melds.push(taken[0]);
      playerState[me] = {
        rack: playerState[me].rack,
        melds: melds,
      };
    } else {
      const tile = melds.splice(0, 1);
      discard.push(tile[0]);
      playerState[me] = {
        rack: playerState[me].rack,
        melds: melds,
      };
    }
    const newState: any = { playerState };
    newState[pile] = discard;
    this.commitAction(
      new DiscardTendaysTile(me, playerState[me], pile, discard)
    );
  };

  place = async (position: number): Promise<void> => {
    if (!this.props.auth.email) {
      // TODO: fail in some visible way
      console.log("ERROR: player is not signed in");
      return;
    }
    const me = this.props.auth.email;

    let playerState: PlayerStateMap = { ...this.props.playerState };
    let melds = [...playerState[me].melds];
    let rack = [...playerState[me].rack];
    const inTile = melds.splice(0, 1);
    const outTile = rack.splice(position, 1);
    rack.splice(position, 0, inTile[0]);
    if (outTile[0] >= 0) {
      melds.splice(0, 0, outTile[0]);
    } else {
      melds.splice(0, 1);
    }
    playerState[me] = {
      rack: rack,
      melds: melds,
    };
    this.commitAction(
      new UpdateTendaysRackAction(me, playerState[me])
    );
  };

  renderGame(): ReactNode {
    const props = this.props;
    const me = props.auth.email || "";
    const deck = this.props.deck;
    const rack = props.playerState[me].rack;
    const melds = props.playerState[me].melds;
    return (
      <div className={classNames(styles.skyblue, tabletops.felt)}>
        <h2>Tendays Game Code: {props.code}</h2>
        <div className={styles.center}>
          <span className={styles.board} />
        </div>
        <div className={styles.center}>
          <div className={styles.discards}>
            <div className={styles.center}>
              <div>
                <DiscardPile
                  draw={() => this.takeDiscard("discardA")}
                  deck={props.deck}
                  pile={props.discardA}
                />
              </div>
            </div>
            <div className={styles.center}>
              <DiscardPile
                draw={() => this.takeDiscard("discardB")}
                deck={props.deck}
                pile={props.discardB}
              />
            </div>
            <div className={styles.center}>
              <DiscardPile
                draw={() => this.takeDiscard("discardC")}
                deck={props.deck}
                pile={props.discardC}
              />
            </div>
            <div className={styles.center}>
              <Tile
                className={styles.glossytile}
                name={styles.cardback}
                onClick={this.drawTile}
              />
            </div>
          </div>
        </div>
        {props.playerState && (
          <Rack {...props.playerState[me]} deck={this.props.deck}>
            <Group
              className={styles.hand}
              tileClassName={styles.glossytile}
              deck={deck}
              tiles={tiles}
              indices={melds}
            />
            <Group
              className={styles.rack}
              tileClassName={styles.glossytile}
              backClassName={styles.cardback}
              deck={deck}
              tiles={tiles}
              indices={rack}
              place={this.place}
            />
          </Rack>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapPropsToDispatch)(Tendays);
