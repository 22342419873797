import { Reducer, AnyAction } from "redux";
import { PendingState, GameState } from "../../components/game";

export type SegmentState = {
  points: { x: number; y: number }[];
  color: string;
  width: number;
  player: number;
};

export interface SketchState extends GameState {
  width: number;
  height: number;
  segment: SegmentState[];
  orderedPlayers: string[];
  removedPlayers: { [email: string]: number };
  leaderboard: { [email: string]: number };
  currentPlayer: number;
  currentWord: string;
  isGameOver: boolean;
  wordChoices: string[];
  timeRemaining: number;
  playersRemaining: { [email: string]: boolean };
  roundNumber: number;
  shuffledWords: string[];
  topWord: number;
  penWidth: number;
  penColor: string;
  clearInFlight: boolean;
  customColors: string[];
  variant: string;
  mrwhite: number;
}

export const ROUND_TIME = 90;
export const NUM_ROUNDS = 3;
export const NUM_CHOICES = 2;

export const sketch_internal: Reducer<SketchState> = (
  state = {
    width: window.innerWidth,
    height: window.innerHeight,
    segment: [],
    orderedPlayers: [],
    removedPlayers: {},
    playersRemaining: {},
    roundNumber: NUM_ROUNDS - 1,
    wordChoices: [],
    leaderboard: {},
    currentPlayer: 0,
    currentWord: "Game Not Started",
    isGameOver: true,
    timeRemaining: ROUND_TIME,
    shuffledWords: [],
    topWord: 0,
    penWidth: 10,
    penColor: "black",
    clearInFlight: false,
    variant: "Scribble",
    mrwhite: 0,

    // TODO: we should not have to initialize GameState here.
    game: "sketch",
    title: "Just a sketchpad",
    description: "Draw away :)",
    started: false,
    creator: "",
    loaded: false,
    players: [],
    auth: {},
    pendingActions: true,
    customColors: [ "cyan", "magenta" ]
  },
  action: AnyAction
) => {
  switch (action.type) {
    case "PEN_WIDTH": {
      const ret = { ...state };
      ret.penWidth = action.width;
      return ret;
    }
    case "PEN_COLOR": {
      const ret = { ...state };
      ret.penColor = action.color;
      return ret;
    }
    case "ADD_PEN_COLOR": {
      if (action.timestamp === null) return state;
      const ret = {...state};
      ret.customColors = [...ret.customColors, action.color];
      return ret;
    }
    case "RESIZE_CANVAS":
      return { ...state, width: action.width, height: action.height };
    case "DRAW_POINTS": {
      const segment = [...state.segment];
      segment.push(action.segment);
      return { ...state, segment };
    }
    case "REMOVE_PLAYER": {
      if (action.timestamp === null) return state;
      // The following properties are impacted by the removal of a player.
      // orderedPlayers: string[];
      // removedPlayers: { [email: string]: boolean };
      // leaderboard: { [email: string]: number };
      // currentPlayer: number;
      // playersRemaining: { [email: string]: boolean };
      // mrwhite: number;
      let ret = { ...state };

      ret.orderedPlayers = [ ...state.orderedPlayers ];
      let index = ret.orderedPlayers.indexOf(action.email);
      if (index === -1) {
        return state;
      }

      ret.orderedPlayers.splice(index, 1);

      ret.removedPlayers = { ...state.removedPlayers };
      ret.removedPlayers[action.email] = ret.leaderboard[action.email];

      ret.leaderboard = { ...state.leaderboard };
      delete ret.leaderboard[action.email];

      if (ret.currentPlayer >= index) {
        ret.currentPlayer = ret.currentPlayer - 1;
      }

      ret.playersRemaining = { ...state.playersRemaining };
      delete ret.playersRemaining[action.email];

      if (ret.mrwhite >= index) {
        ret.mrwhite = ret.mrwhite - 1;
      }

      return ret;
    }
    case "JOIN_PLAYER": {
      if (action.timestamp === null) return state;
      if (action.code !== state.code || action.game !== state.game)
        return state;
    }
    // fall through
    case "LATE_JOIN": {
      let ret = { ...state };
      if (ret.leaderboard[action.email] === undefined) {
        ret.orderedPlayers = [...ret.orderedPlayers, action.email];
        ret.leaderboard = { ...ret.leaderboard };
        const LARGEST_POSSIBLE_SCORE = 100000000;
        let minScore = LARGEST_POSSIBLE_SCORE;
        Object.keys(ret.leaderboard).forEach(key => {
          minScore = Math.min(ret.leaderboard[key], minScore);
        });
        if (minScore === LARGEST_POSSIBLE_SCORE) {
          minScore = 0;
        }
        if (ret.removedPlayers[action.email]) {
          minScore = ret.removedPlayers[action.email];
        }
        ret.leaderboard[action.email] = minScore;
        ret.playersRemaining = { ...state.playersRemaining };
        ret.playersRemaining[action.email] = true;
      }
      return ret;
    }
    case "SCORE": {
      if (action.timestamp === null) return state;
      const ret = { ...state };
      ret.leaderboard = { ...ret.leaderboard };
      if (ret.playersRemaining[action.email]) {
        ret.leaderboard[action.email] =
          ret.leaderboard[action.email] +
          100 * Object.keys(ret.playersRemaining).length;
        let drawer = ret.orderedPlayers[ret.currentPlayer];
        ret.leaderboard[drawer] += 100;
        ret.playersRemaining = { ...ret.playersRemaining };
        delete ret.playersRemaining[action.email];
      }
      const remaining = Object.keys(ret.playersRemaining);
      if (remaining.length === 1) {
        const drawingPlayer = remaining[0];
        let scoreIncrement = action.time * 5;
        scoreIncrement = Math.round(scoreIncrement / 10) * 10;
        ret.leaderboard[drawingPlayer] =
          ret.leaderboard[drawingPlayer] + scoreIncrement;
      }
      return ret;
    }
    case "CHOOSE_WORD": {
      if (action.timestamp === null) return state;
      const ret = { ...state };
      ret.currentWord = action.word;
      ret.wordChoices = [];
      return ret;
    }
    case "CLEAR_IN_FLIGHT": {
      const ret = {...state};
      ret.clearInFlight = true;
      return ret;
    }
    case "NEXT_PLAYER": {
      if (action.timestamp === null) return state;
      const ret = { ...state };
      if (ret.currentPlayer < ret.orderedPlayers.length - 1) {
        ret.currentPlayer++;
      } else if (ret.roundNumber > 0) {
        ret.roundNumber -= 1;
        ret.currentPlayer = 0;
      } else {
        ret.isGameOver = true;
      }
      return ret;
    }
    case "NEXT_DRAWING": {
      if (action.timestamp === null) return state;
      const ret = { ...state };
      ret.clearInFlight = false;
      if (ret.currentPlayer < ret.orderedPlayers.length - 1) {
        ret.currentPlayer++;
      } else if (ret.roundNumber > 0) {
        ret.roundNumber -= 1;
        ret.currentPlayer = 0;
      } else {
        ret.isGameOver = true;
      }
      if (!ret.isGameOver) {
        ret.wordChoices = [];
        for (let i = 0; i < NUM_CHOICES; ++i) {
          ret.wordChoices.push(ret.shuffledWords[ret.topWord++]);
        }
      }
      ret.timeRemaining = ROUND_TIME;
      ret.playersRemaining = {};
      ret.orderedPlayers.forEach((p) => {
        ret.playersRemaining[p] = true;
      });
      // start with a clear command.
      ret.segment = [{ player: ret.currentPlayer, points: [], color: "white", width: 0 }];
      ret.penColor = "black";
      ret.penWidth = 4;
      return ret;
    }
    case "TICK": {
      const ret = { ...state };
      ret.timeRemaining = action.time;
      return ret;
    }
    case "SHUFFLE_WORDS": {
      const ret = { ...state };
      ret.shuffledWords = action.words;
      ret.variant = action.variant;
      return ret;
    }
    case "SET_SKETCH_VARIANT_ACTION":
      return {...state, variant: action.variant};
    case "NEW_GAME": {
      if (action.timestamp === null) return state;
      const ret = { ...state };
      ret.isGameOver = false;
      ret.roundNumber = NUM_ROUNDS - 1;
      ret.timeRemaining = ROUND_TIME;
      ret.currentPlayer = 0;
      ret.wordChoices = [];
      for (let i = 0; i < NUM_CHOICES; ++i) {
        ret.wordChoices.push(ret.shuffledWords[ret.topWord++]);
      }
      ret.mrwhite = action.mrwhite;
      ret.leaderboard = { ...ret.leaderboard };
      ret.segment = [];
      ret.orderedPlayers = action.orderedPlayers;
      ret.orderedPlayers.forEach((email) => {
        ret.leaderboard[email] = 0;
      });
      return ret;
    }
    case "START_GAME": {
      const ret = { ...state };
      ret.playersRemaining = {};
      ret.orderedPlayers.forEach((p) => {
        ret.playersRemaining[p] = true;
      });
      return ret;
    }
    default:
      return state;
  }
};

export type PendingSketchState = PendingState<SketchState>;

export const sketch = sketch_internal;
