import React, { Component, ReactNode } from "react";
import { Tile } from "./tiles";

type RackProps = {
  rack: number[];
  melds: number[];
  deck?: string[];
};

const noop = (): void => {};

export const Group = ({
  indices,
  deck,
  className,
  tileClassName,
  backClassName,
  tiles,
  place,
}: {
  indices: number[];
  deck: string[];
  tiles: { [key: string]: string };
  tileClassName?: string;
  backClassName?: string;
  className?: string;
  onClick?: () => void;
  place?: (i: number) => void;
}) => {
  let i = 0;
  return (
    <div className={className}>
      {indices.map((tilenumber: number) => {
        const tilename = deck[tilenumber];
        let tile = tiles[tilename];
        if (!tile && backClassName) {
          tile = backClassName;
        }
        let index = i++;
        let onClick = place ? () => place(index) : null;
        return (
          <Tile
            className={tileClassName}
            key={tile + tilenumber}
            name={tile}
            onClick={onClick || noop}
          />
        );
      })}
    </div>
  );
};

export class Rack extends Component<RackProps> {
  render(): ReactNode {
    return <div style={{ lineHeight: "0" }}>{this.props.children}</div>;
  }
}
