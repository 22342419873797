export function signInAction(
  name: string | null,
  email: string,
  photo: string | null
): { type: string; email: string; name: string | null; photo: string | null } {
  return { type: "SIGN_IN", email: email, name: name, photo: photo };
}

export function signOutAction(): { type: string } {
  return { type: "SIGN_OUT" };
}

export function authInProgressAction(): { type: string } {
  return { type: "AUTH_WAITING" };
}

export function authUnknown(): { type: string } {
  return { type: "AUTH_UNKNOWN" };
}

export function authErrorAction(
  err: string
): { type: string; message: string } {
  return { type: "AUTH_ERROR", message: err };
}
