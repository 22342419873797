import { TendaysState } from "./reducers";
import { BaseAction } from "../mahjong/actions";
import { PlayerStateMap, MahjongRack } from "../mahjong/reducers";

export function setFieldsAction(
  fields: TendaysState
): TendaysState & { type: string } {
  return { ...fields, type: "SET_TENDAYS" };
}

export class TendaysSetupAction extends BaseAction {
  constructor(
    readonly deck: string[],
    readonly top: number,
    readonly playerState: PlayerStateMap,
    readonly discardA: number[],
    readonly discardB: number[],
    readonly discardC: number[]
  ) {
    super();
    this.type = "TENDAYS_SETUP";
  }
}
export class UpdateTendaysRackAction extends BaseAction {
  constructor(
    readonly email: string,
    readonly rack: MahjongRack
  ) {
    super();
    this.type = "UPDATE_TENDAYS_RACK";
  }
}

export class DrawTileAction extends BaseAction {
  constructor(
    readonly email: string,
  ) {
    super();
    this.type = "DRAW_TENDAYS_TILE";
  }
}
export class DiscardTendaysTile extends BaseAction {
  constructor(
    readonly email: string,
    readonly rack: MahjongRack,
    readonly pile: string,
    readonly discard: number[]
  ) {
    super();
    this.type = "DISCARD_TENDAYS_TILE";
  }
}
