export const shuffle = (deck: string[]): string[] => {
  const shuffled = [...deck];
  for (let i = shuffled.length - 1; i > 0; --i) {
    const j = Math.floor(Math.random() * i);
    const t = shuffled[j];
    shuffled[j] = shuffled[i];
    shuffled[i] = t;
  }
  return shuffled;
};
