import React, { FormEvent, RefObject } from "react";
import { Component, ReactNode } from "react";
import styles from "./lobby.module.css";
import { TextField, Button } from "@material-ui/core";
import { createGame } from "./gameprimitives";

export type Lobby = {
  game: string;
  title: string;
  description: string;
  setup: (
    doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  ) => Promise<void>;
};

export class GameLobby extends Component<Lobby> {
  input: RefObject<HTMLInputElement> = React.createRef();
  joinGame = (event: FormEvent<HTMLFormElement>): void => {
    // Prevent submission of the form when pressing Enter.
    event.preventDefault();
    if (!this.input.current) return;
    const text = this.input.current.value;
    this.input.current.value = "";
    window.location.href = text;
  };

  createGameClick = async (): Promise<void> => {
    let doc = await createGame(this.props.game);
    await this.props.setup(doc);
  };

  render(): ReactNode {
    const { title, description } = this.props;
    return (
      <div className={styles.welcome}>
        <h1>{title}</h1>
        <p>{description}</p>
        <form onSubmit={this.joinGame}>
          <TextField
            fullWidth
            variant="outlined"
            label="Enter Game Code to Join"
            inputRef={this.input}
          />
        </form>
        <p>Don&apos;t have a game code?</p>
        {this.props.children}
        <Button variant="contained" onClick={this.createGameClick}>
          Create A New Game
        </Button>
      </div>
    );
  }
}
