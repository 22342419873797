import firebase from "firebase";

const config = {
  projectId: "boardgames-dc286",
  databaseURL: "https://boardgames-dc286.firebaseio.com",
  storageBucket: "boardgames-dc286.appspot.com",
  locationId: "us-east1",
  apiKey: "AIzaSyA9iahTsSAYbQAW4GWT_RvksNcx2tZ_uoc",
  authDomain: "boardgames-dc286.firebaseapp.com",
  messagingSenderId: "530980495209"
};

const alexConfig = {
  apiKey: "AIzaSyBrKm_IOAqJEdPINvYwniTkWl912Sc3sto",
  authDomain: "boardgames-alex.firebaseapp.com",
  databaseURL: "https://boardgames-alex.firebaseio.com",
  projectId: "boardgames-alex",
  storageBucket: "boardgames-alex.appspot.com",
  messagingSenderId: "708206366741",
  appId: "1:708206366741:web:3b96df95d9f95045ad3e1c"
};

firebase.initializeApp(config);

export default firebase;
