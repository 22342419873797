import React from "react";
import { Component, ReactNode } from "react";
import styles from "./lobby.module.css";
import { AuthState } from "../routes/login/reducers";
import { Button } from "@material-ui/core";

type Props = {
  code: string;
  game: string;
  title: string;
  creator: string;
  players: string[];
  auth: AuthState;
  joinGame: () => Promise<void>;
  startGame: () => Promise<void>;
};

export class GameElevator extends Component<Props> {
  componentDidMount() {
    const props = this.props;
    if (!props.players.includes(props.auth.email || "")) {
      this.props.joinGame();
    }
  }

  render(): ReactNode {
    const props = this.props;
    const { title } = this.props;
    const isCreator = this.props.auth.email === props.creator;
    return (
      <div className={styles.welcome}>
        <h1>
          {title} Code: {props.code}
        </h1>
        <p>Creator: {props.creator}</p>
        {props.players.map((player) => {
          return (
            <p key={player} id={player}>
              Player: {player}
            </p>
          );
        })}
        {isCreator || <p>Please wait for the game to start...</p>}
        {this.props.children}
        {isCreator && (
          <Button variant="contained" onClick={this.props.startGame}>
            Start Game
          </Button>
        )}
      </div>
    );
  }
}
