import React from "react";

export const Tile = ({
  name,
  className,
  onClick
}: {
  name: string;
  className?: string;
  onClick?: () => void;
}) => (
  <span onClick={onClick} className={name + " " + className}>
    <div></div>
  </span>
);