import React, { Component, ReactNode } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider, connect, ConnectedProps } from "react-redux";
import { store, State } from "../store";

import Chat from "../routes/chat";
import Login from "../routes/login";
import Users from "../routes/users";
import Scramble from "../routes/scramble";
import Sketch from "../routes/sketch";
import Cards from "../routes/cards";
import Mahjong from "../routes/mahjong";
import Tendays from "../routes/tendays";

const mapStateToProps = (state: State): State => ({
  ...state,
});
const mapPropsToDispatch = {};

const connector = connect(mapStateToProps, mapPropsToDispatch);
type Props = ConnectedProps<typeof connector>;

class AppDivClass extends Component<Props> {
  render(): ReactNode {
    return (
      <div id="app">
        {this.props.auth.signedIn && (
          <BrowserRouter>
            <Switch>
              <Route path="/users">
                <Users />
              </Route>
              <Route
                path="/chat/:room"
                render={({ match }) => <Chat room={match.params.room} />}
              />
              <Route path="/chat">
                <Chat room="chats" />
              </Route>
              <Route
                path="/scramble/:code"
                render={({ match }) => <Scramble code={match.params.code} />}
              />
              <Route path="/scramble">
                <Scramble />
              </Route>
              <Route
                path="/sketch/:code"
                render={({ match }) => <Sketch code={match.params.code} />}
              />
              <Route path="/sketch">
                <Sketch />
              </Route>
              <Route
                path="/cards/:code"
                render={({ match }) => <Cards code={match.params.code} />}
              />
              <Route path="/cards">
                <Cards />
              </Route>
              <Route
                path="/mahjong/:code"
                render={({ match }) => <Mahjong code={match.params.code} />}
              />
              <Route path="/mahjong">
                <Mahjong />
              </Route>
              <Route
                path="/tendays/:code"
                render={({ match }) => <Tendays code={match.params.code} />}
              />
              <Route path="/tendays">
                <Tendays />
              </Route>
              <Route path="/">
                <Login />
              </Route>
            </Switch>
          </BrowserRouter>
        )}
        {this.props.auth.signedIn || <Login />}
      </div>
    );
  }
}
const AppDiv = connector(AppDivClass);

class App extends Component {
  render(): ReactNode {
    return (
      <Provider store={store}>
        <AppDiv />
      </Provider>
    );
  }
}

export default App;
