import React from "react";
import { Component, ReactNode } from "react";
import { Button } from "@material-ui/core";
import styles from "./style.module.css";
import firebase from "../../components/firebase";
import * as actions from "./actions";
import { store, State } from "../../store";
import { connect } from "react-redux";
import { AuthState } from "./reducers";

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

class Login extends Component<AuthState> {
  signin = (): void => {
    auth.signInWithPopup(googleAuthProvider).catch((error) => {
      store.dispatch(actions.authErrorAction(error.error));
    });
  };

  signout = (): void => {
    auth.signOut().catch((error) => {
      store.dispatch(actions.authErrorAction(error.error));
    });
  };

  render(): ReactNode {
    let { signedIn, name, email, photo, authMessage } = this.props;
    return (
      <div className={styles.home}>
        <h1>Login</h1>
        {signedIn && (
          <div>
            <img alt={name} src={photo} width="64" />
            <p>{name}</p>
            <p>{email}</p>
          </div>
        )}
        {authMessage && <pre>{authMessage}</pre>}
        {signedIn || (
          <Button variant="contained" onClick={this.signin}>
            Sign in
          </Button>
        )}
        {signedIn && (
          <Button variant="contained" onClick={this.signout}>
            Sign out
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: State): AuthState => ({ ...state.auth });
const mapPropsToDispatch = actions;

export default connect(mapStateToProps, mapPropsToDispatch)(Login);
