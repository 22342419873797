import { BaseAction } from "../../components/actions";
import { AnyAction } from "redux";

export class SetInitialStateAction extends BaseAction {
  constructor(readonly deck: string[], readonly cardsPerHand: number, readonly gameType: string, readonly tileset: string) {
    super();
    this.type = "SET_INITIAL_STATE";
  }
}

// TODO: make this a generic action with a generic name.
export class MoveCardAction extends BaseAction {
  constructor(
    readonly sourcePile: string,
    readonly sourceIndex: number,
    readonly destPile: string,
    readonly destIndex: number,
  ) {
    super();
    this.type = "MOVE_CARD";
  }
}

// TODO: make this a generic action with a generic name.
export class SetPlayerOrderAction extends BaseAction {
  constructor(readonly playerOrder: string[]) {
    super();
    this.type = "SET_PLAYER_ORDER";
  }
}

export class SetNumberOfCardsPerHandAction extends BaseAction {
  constructor(readonly n: number) {
    super();
    this.type = "SET_NUMBER_OF_CARDS_PER_HAND";
  }
}

export class SetGameTypeAction extends BaseAction {
  constructor(readonly gameType: string) {
    super();
    this.type = "SET_GAME_TYPE";
  }
}

export class TakeCurrentPlayerTokenAction extends BaseAction {
  constructor(readonly currentPlayer: number) {
    super();
    this.type = "TAKE_CURRENT_PLAYER_TOKEN";
  }
}

export class SetTilesetAction extends BaseAction {
  constructor(readonly tileset: string) {
    super();
    this.type="SET_TILESET";
  }
}

export class ShowTopCardAction extends BaseAction {
  constructor(readonly show: boolean) {
    super();
    this.type="SHOW_TOP_CARD";
  }
}

export class UpdatePileAction extends BaseAction {
  constructor(readonly pileName: string, readonly pile: number[]) {
    super();
    this.type="UPDATE_PILE";
  }
}

export class OpenCardsDrawerAction extends BaseAction {
  constructor(readonly isOpen: boolean) {
    super();
    this.type="OPEN_CARDS_DRAWER";
  }
}
