import React, { FormEvent, RefObject } from "react";
import { Component, ReactNode } from "react";
import { TextField } from "@material-ui/core";
import firebase from "../../components/firebase";
import * as actions from "./actions";
import { connect, ConnectedProps } from "react-redux";
import { ChatRoom, ChatMessage as ChatMessageType } from "./reducers";
import { AuthState } from "../login/reducers";
import { Users } from "../users/reducers";
import ChatMessage from "../../components/chatmessage";
import styles from "./style.module.css";
import { State } from "../../store";

const mapStateToProps = (
  state: State
): ChatRoom & { auth: AuthState } & { users: Users } => ({
  ...state.chat,
  auth: state.auth,
  users: state.users,
});
const mapPropsToDispatch = actions;

const connector = connect(mapStateToProps, mapPropsToDispatch);
type Props = ConnectedProps<typeof connector> & { room: string, inputTransform?: (text: string) => string };

class Chat extends Component<Props> {
  input: RefObject<HTMLInputElement> = React.createRef();

  getRoomPath(): string {
    return this.props.room.replace(/:/g, "/");
  }
  sendMessage = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    // Prevent submission of the form when pressing Enter.
    event.preventDefault();
    if (!this.input.current || !this.props.auth.email) return;
    let text = this.input.current.value;
    this.input.current.value = "";
    if (this.props.inputTransform) {
      text = this.props.inputTransform(text);
    }
    await this.send(text);
  };

  private async send(text: string) {
    const now = new Date().getTime();
    const email = this.props.auth.email || "";
    try {
      const doc = firebase.firestore().collection(this.getRoomPath()).doc();
      this.props.createMessagePendingAction(doc.id, this.props.room, email, text, now);
      await doc.set({
        room: this.props.room,
        name: this.props.auth.email,
        text: text,
        timestamp: now,
        id: doc.id,
      });
    }
    catch (error) {
      // TODO: add an error action to our reducers for chat messages.
      console.error("Error writing new message to database", error);
    }
  }

  unsubscribe: Function = () => {
    throw Error("ERROR: unexpected unsubscribe not assigned");
  };

  componentDidMount(): void {
    const query = firebase
      .firestore()
      .collection(this.getRoomPath())
      .orderBy("timestamp", "asc");

    const props = this.props;
    this.unsubscribe = query.onSnapshot(function (snapshot) {
      snapshot.docChanges().forEach(function (change) {
        const message = change.doc.data();
        props.createMessageAction(
          change.doc.id,
          message.room || "chats",
          message.name,
          message.text,
          message.timestamp
        );
      });
    });
  }

  componentWillUnmount(): void {
    this.unsubscribe();
  }

  render(): ReactNode {
    let message_ids: string[] = [];
    let idToMessageMap: {[id: string]: ChatMessageType } = {};
    let aggregatedIds: string[][] = [];
    const roomState = this.props.roomMap[this.props.room];
    if (roomState) {
      message_ids = roomState.message_ids;
      idToMessageMap = roomState.idToMessageMap;
      aggregatedIds = this.aggregate(message_ids, idToMessageMap);
    }
    return (
      <div className={styles.main}>
        <div className={styles.messages}>
          {aggregatedIds.map((ids) => {
            return (
              <ChatMessage
                key={ids[0]}
                user={this.props.users.users[roomState.idToMessageMap[ids[0]].email]}
                currentUser={this.props.auth}
                messages={ids.map(id => {
                  return idToMessageMap[id];
                })}
              />
            );
          })}
          <div id="bottom" className={styles.sentinel}></div>
          {document.getElementById("bottom")?.scrollIntoView()}
        </div>
        <form onSubmit={this.sendMessage}>
          <TextField fullWidth variant="outlined" inputRef={this.input} />
        </form>
      </div>
    );
  }

  private aggregate(
    message_ids: string[],
    idToMessageMap: {
      [id: string]: ChatMessageType;
    }
  ): string[][] {
    const aggregatedIds = [];
    const TEN_MINUTE_LIMIT_FOR_SPEECH_BUBBLE = 600 * 1000;
    let lastMessage = undefined;
    for (let i = 0; i < message_ids.length; ++i) {
      const currentMessage = idToMessageMap[message_ids[i]];
      let timeDelta = 0;
      if (lastMessage) {
        timeDelta = currentMessage.timestamp - lastMessage.timestamp;
      }
      if (
        lastMessage &&
        currentMessage.email === lastMessage.email &&
        timeDelta < TEN_MINUTE_LIMIT_FOR_SPEECH_BUBBLE
      ) {
        aggregatedIds[aggregatedIds.length - 1].push(message_ids[i]);
      } else {
        aggregatedIds.push([message_ids[i]]);
        lastMessage = currentMessage;
      }
    }
    return aggregatedIds;
  }
}

export default connector(Chat);
