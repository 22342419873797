export class ScrambleGame {
  stride: number = 0;
  letters: string = "";

  constructor(stride: number, letters: string) {
    this.stride = stride;
    this.letters = letters;
  }
}

// Taken from letter counts in English dictionary.
const COUNTS = [
  38593, // a
  9937,
  21272,
  20937,
  61239, // e
  7491,
  16702,
  11765,
  45775, // i
  974,
  4903,
  27185,
  13955,
  37115,
  31145, // o
  15369,
  1021,
  37680,
  47260,
  36271,
  17615 - 1021, // Subtract the number of 'U's from 'Q's.
  5361,
  4820,
  1436,
  7926,
  2094
];

const VOWEL_INDICES = [0, 4, 8, 14, 20];

enum Distribution {
  ENGLISH,
  FAVOR_VOWELS
}

class ScrambleTemplate {
  stride: number = 0;
  cells: string[] = [];

  constructor(stride: number, cells: string) {
    this.stride = stride;
    this.cells = cells.split("").filter(e => e !== "\n");
  }

  generateRandomLetter(distribution: Distribution) {
    // Vowels become |VOWEL_MULTIPLIER| times more likely in the FAVOR_VOWELS
    // distribution. This number is pretty arbitrary.
    const VOWEL_MULTIPLIER = 15.0;
    let favorVowels = distribution === Distribution.FAVOR_VOWELS;
    let total = 0;
    for (let i = 0; i < COUNTS.length; i++) {
      let increment = COUNTS[i];
      if (favorVowels && VOWEL_INDICES.includes(i)) {
        increment *= VOWEL_MULTIPLIER;
      }
      total += increment;
    };

    let chosen = Math.floor(Math.random() * total);
    for (let i = 0; i < COUNTS.length; i++) {
      let increment = COUNTS[i];
      if (favorVowels && VOWEL_INDICES.includes(i)) {
        increment *= VOWEL_MULTIPLIER;
      }
      if (chosen <= increment) {
        return String.fromCharCode(65 + i);
      }
      chosen -= increment;
    }
  }

  generateGame(): ScrambleGame {
    return new ScrambleGame(
      this.stride,
    this.cells.map(e => {
      switch (e) {
        case "n":
          return this.generateRandomLetter(Distribution.ENGLISH);
        case "v":
          return this.generateRandomLetter(Distribution.FAVOR_VOWELS);
        case "*":
          // TODO: update this to emit a wild card when supported.
          return this.generateRandomLetter(Distribution.FAVOR_VOWELS);
        case "_":
          return e;
        default:
          throw new Error("Unexpected character in template.");
      }
    }).join(""));
  }
}

const TEMPLATES = [
  new ScrambleTemplate(
    5,
    `
nnn__
nnnn_
nnnnn
_nnnn
__nnn`
  ),
  new ScrambleTemplate(
    5,
    `
_nnn_
nvnvn
nn_nn
n*nvn
_nnn_`
  ),
  new ScrambleTemplate(
    7,
    `
__nnn__
_nvnvn_
nnn_nnn
_n*nvn_
__nnn__`
  ),
  new ScrambleTemplate(
    6,
    `
nn*nnn
nn_nnn
nnn_nn
nnnvnn`
  ),
  new ScrambleTemplate(
    5,
    `
nnnn_
nnnnn
nnnnn
_nnnn`
  ),
  new ScrambleTemplate(
    4,
    `
nnnn
nnnn
*__v
nnnn
nnnn`
  ),
  new ScrambleTemplate(
    6,
    `
_nnnn_
nvnnvn
nn__nn
nvnnvn
_nnnn_`
  ),
  new ScrambleTemplate(
    5,
    `
_nnnn
nvnnn
nn_nn
nnn*n
nnnn_`
  ),
  new ScrambleTemplate(
    7,
    `
nnn_nnn
nnn*nnn
nnn_nnn`
  )
];

export function generateScrambleGame(): ScrambleGame {
  let i = Math.floor(Math.random() * TEMPLATES.length);
  return TEMPLATES[i].generateGame();
}
