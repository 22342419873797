export function joinPlayerAction(
  email: string,
  game: string,
  code: string
): { email: string; game: string; code: string, type: string } {
  return {
    type: "JOIN_PLAYER",
    email: email,
    game: game,
    code: code
  };
}

export function startGameAction(game: string, code: string): { game: string; code: string; type: string } {
  return {
    type: "START_GAME",
    game: game,
    code: code
  };
}

export function navigateToGameAction(game: string, code: string): { game: string; code: string; type: string } {
  return {
    type: "NAVIGATE_TO_GAME",
    game: game,
    code: code
  };
}

export function enterElevatorAction(
  email: string,
  game: string,
  code: string
): { email: string; game: string; code: string, type: string } {
  return {
    type: "ENTER_ELEVATOR",
    email: email,
    game: game,
    code: code
  };
}