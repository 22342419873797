import { SegmentState } from "./reducers";
import { BaseAction } from "../../components/actions";

export class ShuffleWordsAction extends BaseAction {
  constructor(readonly variant: string, readonly words: string[]) {
    super();
    this.type = "SHUFFLE_WORDS";
  }
}

export class ResizeCanvasAction extends BaseAction {
  constructor(readonly width: number, readonly height: number) {
    super();
    this.type = "RESIZE_CANVAS";
  }
}

export class PenWidthAction extends BaseAction {
  constructor(readonly width: number) {
    super();
    this.type = "PEN_WIDTH";
  }
}

export class PenColorAction extends BaseAction {
  constructor(readonly color: string) {
    super();
    this.type = "PEN_COLOR";
  }
}

export class SetSketchVariantAction extends BaseAction {
  constructor(readonly variant: string) {
    super();
    this.type = "SET_SKETCH_VARIANT_ACTION";
  }
}

export class CustomColorAction extends BaseAction {
  constructor(readonly color: string) {
    super();
    this.type = "ADD_PEN_COLOR";
  }
}

export class ClearInFlightAction extends BaseAction {
  constructor() {
    super();
    this.type = "CLEAR_IN_FLIGHT";
  }
}

export class ChooseWordAction extends BaseAction {
  constructor(readonly word: string) {
    super();
    this.type = "CHOOSE_WORD";
  }
}
export class DrawPointsAction extends BaseAction {
  constructor(readonly segment: SegmentState) {
    super();
    this.type = "DRAW_POINTS";
  }
}

export class ScoreAction extends BaseAction {
  constructor(readonly email: string, readonly time: number) {
    super();
    this.type = "SCORE";
  }
}

export class NewGameAction extends BaseAction {
  constructor(readonly mrwhite: number, readonly orderedPlayers: string[]) {
    super();
    this.type = "NEW_GAME";
  }
}

export class TickAction extends BaseAction {
  constructor(readonly time: number, readonly timestamp: number = -1) {
    super();
    this.type = "TICK";
  }
}

export class NextPlayerAction extends BaseAction {
  constructor() {
    super();
    this.type = "NEXT_PLAYER";
  }
}

export class NextDrawingAction extends BaseAction {
  constructor(
    readonly timestamp: number = -1
  ) {
    super();
    this.type = "NEXT_DRAWING";
  }
}

export class RemovePlayerAction extends BaseAction {
  constructor(
    readonly email: string
  ) {
    super();
    this.type = "REMOVE_PLAYER";
  }
}

export class LateJoinAction extends BaseAction {
  constructor(
    readonly email: string
  ) {
    super();
    this.type = "LATE_JOIN";
  }
}
